<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
	<li class="breadcrumb-item"><a href="javascript:;">Inicio</a> / Movilizados / Informe Integrado de movilización</li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->
<div class="row justify-content-center h-100">
	<div class="col-md-10">
		<div class="panel panel-iatuch">
			<div class="panel-heading">
				<h4 class="panel-title">{{titulo}} </h4>
			</div>
			<div *ngIf="vencimiento == false || !periodo" class="alert alert-danger fade show">
				<p class="mb-0" *ngIf="vencimiento == false"><i class="fa fa-exclamation-circle"></i> El Plazo para
					enviar el Informe ha Terminado!! </p>
				<p class="mb-0" *ngIf="!periodo"><i class="fa fa-exclamation-circle"></i> No Existe Periodo Activo!!
				</p>
			</div>
			<div *ngIf="periodo" class="alert {{alert}} fade show pb-1 pt-2 mb-0">
				<!--<i class="fa {{icon}} fa-2x pull-left m-r-10"></i>-->

				<p class="m-0"><i class="fa fa-calendar-check"></i> <b> {{mensaje}}</b> &nbsp;
					<label *ngIf="distrito"><i class="fa fa-map-marker"></i>
						<b> Distrito</b>: {{distrito.nombre}}</label> &nbsp;
					<label *ngIf="distrito"><i class="fa fa-user"></i>
						<b> Pastor:</b> {{pastor.nombre}} {{pastor.apellido}}</label>
				</p>
				<p *ngIf="periodo" class="m-0"><i class="fa fa-calendar-times"></i>
					<b> Cierre Informe: </b> {{(periodo.fecha_cierre_informe | date:'dd/MM/yyyy HH:mm')+" Hrs"}}
					<b>&nbsp;<i *ngIf="guardar == true" class="fa fa-lock-open"></i><i *ngIf="guardar == false"
							class="fa fa-lock"></i>
						Estado: </b> {{ periodo.estado.nombre }}
					<label *ngIf="periodo.ultimo_cambio">
						<b>&nbsp;<i class="fa fa-calendar-plus"></i> Ultimo Cambio: </b>
						{{ (periodo.ultimo_cambio | date:'dd/MM/yyyy HH:mm')+" Hrs"}}</label>
				</p>
			</div>
			<div class="panel-body">

				<div class="row justify-content-center h-100 ">
					<div class="col-md-10 pb-0">
						<button *ngIf="periodos" class="btn btn-success btn-xs mt-0 mb-1" (click)="open(periodos)"><i
								class="fa fa-calendar"></i> Elegir Periodo</button>
					</div>
					<div *ngIf="documentacion && periodo" class="col-md-10">
						<div class="card">
							<div class="card-body">
								<div class="mt-3" *ngFor="let i of documentacion;let indice_documentacion=index">
									<h5>{{i.nombre}}</h5>
									<img style="width:100%;" *ngIf="mostrar_img == true" [src]="img + i.imagen">
									<p class="text-justify">{{i.descripcion}}</p>
									<div *ngFor="let p of i.preguntas; let indice_pregunta = index">
										<div *ngIf="indice_pregunta == 0 && p.registros==4 && mostrar_img == false">
											<div class="form-inline border-bottom mb-1">
												<div class="col-md-6">
													<label class="float-left text-left"><b>Inficador</b></label>
												</div>
												<div class="col-md-2 float-right">
													<label><b>Nuevos</b></label>
												</div>
												<div class="col-md-2 float-right">
													<label><b>Finalizados</b></label>
												</div>
												<div class="col-md-2 float-right">
													<label><b>Activos</b></label>
												</div>
											</div>
										</div>
										<div class="form-inline" [ngClass]="{'border mb-1': mostrar_img == false}">
											<div
												[ngClass]="{'col-md-6': p.registros == 4,'col-md-9': p.registros == 1}">
												<label class="float-left text-left">{{ p.nombre }}
													&nbsp;</label> <br> <label *ngIf="p.descripcion"
													class="float-left text-left text-secondary">{{p.descripcion}}</label>
												<div class="p-0 float-left col-md-12">
													<label *ngIf="p.mensaje"
														class="m-0 p-0 float-left text-danger ">{{p.mensaje}}
													</label>
												</div>
											</div>
											<div *ngIf="p.registros == 4 && mostrar_img == true"
												class="col-md-1 float-right ">
												<label class="m-0"><b>Nuevos:</b></label>
											</div>
											<div *ngIf="p.registros == 4" class="col-md-2 float-right"
												[ngClass]="{'border-left': mostrar_img == false}">
												<label>
													<!--<b>Nuevos:</b>&nbsp;-->
													<input *ngIf="guardar == true"
														class="mt-1 ml-0 mb-1 form-control form-control-sm  shadow-none w-100 "
														[class.is-invalid]="p.validacion_nuevos == false"
														[class.is-valid]="p.validacion_nuevos == true  && enviar == true"
														type="text" placeholder="Nuevos" maxlength="4"
														[(ngModel)]="p.nuevos"
														(blur)="totalizador( indice_pregunta, indice_documentacion)">
													<label *ngIf="guardar==false">
														{{ p.nuevos }}
													</label>
												</label>
											</div>
											<div *ngIf="p.registros == 4 && mostrar_img == true"
												class="col-md-1 float-right">
												<label class="m-0"><b>Finalizados:</b>&nbsp;</label>
											</div>
											<div *ngIf="p.registros == 4" class="col-md-2 float-right"
												[ngClass]="{'border-left': mostrar_img == false}">
												<label>
													<!--<b>Finalizados:</b>&nbsp;-->
													<input *ngIf="guardar == true"
														class="mt-1 ml-0 mb-1 form-control form-control-sm  shadow-none  w-100 "
														[class.is-invalid]="p.validacion_finalizados == false"
														[class.is-valid]="p.validacion_finalizados == true && enviar == true"
														type="text" maxlength="4" placeholder="Finalizados"
														[(ngModel)]="p.finalizados"
														(blur)="totalizador( indice_pregunta, indice_documentacion)">

													<label *ngIf="guardar==false"> {{ p.finalizados }} </label>
												</label>
											</div>
											<div *ngIf="p.registros == 4 && mostrar_img == true"
												class="col-md-1 float-right">
												<label class="m-0"><b>Activos:</b>&nbsp;</label>
											</div>
											<div *ngIf="p.registros == 4" class="col-md-2 float-right"
												[ngClass]="{'border-left': mostrar_img  == false}">
												<label>
													<!--<b>Activos:</b>&nbsp;-->
													{{ p.nuevo_activos?p.nuevo_activos:p.activos }}
												</label>
											</div>
											<div *ngIf="p.registros == 1" class="col-md-1 float-right">
												<label class="m-0"><b>Cantidad:</b>&nbsp;</label>
											</div>
											<div *ngIf="p.registros == 1" class="col-md-2 float-right"
												[ngClass]="{'border-left': mostrar_img == false}">
												<label>
													<input *ngIf="guardar == true"
														class="mt-1 ml-0 mb-1 form-control form-control-sm shadow-none w-100 "
														placeholder="Cantidad"
														[class.is-invalid]="p.validacion_cantidad == false"
														[class.is-valid]="p.validacion_cantidad == true && enviar == true"
														type="text" maxlength="4" [(ngModel)]="p.cantidad"
														(blur)="totalizador(indice_pregunta, indice_documentacion)">
													<label *ngIf="guardar==false">
														{{ p.cantidad }}
													</label>
												</label>
											</div>
										</div>
									</div>
								</div>
								<button *ngIf="guardar == true" class="mt-3 mr-1 btn btn-primary"
									(click)="enviarDocumentacion('guardar')">
									<i class="fas fa fa-save"></i> Guardar</button>
								<button *ngIf="cerrar == true" class="mt-3 btn btn-danger"
									(click)="enviarDocumentacion('cerrar')"><i class="fas fa fa-lock"></i>
									Cerrar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- begin panel -->
	</div>
</div>

<ng-template #periodos let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title "><i class="fa fa-calendar"></i> Periodos</h4>
		<button type="button" class="close btn btn-white" (click)="d('Cross 
		    click')">×</button>
	</div>
	<div class="modal-body">
		<div class="card">
			<div *ngIf="resultado" class="card-body">
				<div class="form-inline border-bottom" *ngIf="mostrar_img == false">
					<div class="col-md-3">
						<b>Periodo</b>
					</div>
					<div class="col-md-2">
						<b>Estado</b>
					</div>
					<div class="col-md-2">
						<b>Distrito</b>
					</div>
					<div class="col-md-2">
						<b>Responsable</b>
					</div>
				</div>

				<div class="form-inline border-bottom mt-2 pb-2" *ngFor="let p of resultado.periodos;">
					<div class="col-md-3 text-left">
						{{p.periodo | date:'MM/yyyy'}}
					</div>
					<div class="col-md-2">
						{{p.estado.nombre}}
					</div>
					<div class="col-md-2">
						{{p.distrito.nombre}}
					</div>
					<div class="col-md-2">
						{{p.responsable.usuario}}
					</div>
					<div class="col-md-2">

						<button (click)="seleccionarPeriodo(p._id);d('Cross click')" class="btn btn-xs btn-primary"><i
								class="fa fa-calendar-check"></i>
							Seleccionar</button>

					</div>
				</div>
			</div>


		</div>

	</div>
	<div class="modal-footer">
		<button id="cerrar_modal" class="btn btn-danger" (click)="d('Cross 
		  click')">Cerrar</button>
	</div>
</ng-template>