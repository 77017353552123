import {
	Component,
	Input,
	Output,
	EventEmitter,
	Renderer2,
	OnDestroy,
	OnInit,
	DoCheck,
} from "@angular/core";
import pageSettings from "../../config/page-settings";
import { UserService } from "../../services/user.service";
import { LocalService } from "../../services/localService";
import { EntidadService } from "../../services/entidad.service";
import { NotificacionService } from "../../services/notificaciones.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Entidad } from "../../models/usuario/entidad";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { Global } from "../../services/global";
//import 'rxjs/add/observable/interval';
//import {Observable} from 'rxjs/Observable';
import { interval, Subscription } from 'rxjs';
//import { Observable } from 'rxjs';
//import {async} from 'rxjs';
//import {error} from '@angular/compiler/src/util';

import { environment } from './../../../environments/environment';
import { MicrosoftAuthService } from "../../services/authentication.service";
import { SharingService } from "../../services/sharing.service";

@Component({
	selector: "header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.css"],
	providers: [NotificacionService],
})
export class HeaderComponent implements OnDestroy, OnInit {
	@Input() pageSidebarTwo;
	@Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
	@Output() toggleMobileSidebar = new EventEmitter<boolean>();
	@Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
	pageSettings = pageSettings;

	@Output() sel_entidad = new EventEmitter<string>();
	@Output() mostrar = new EventEmitter<boolean>();
	public mostrar_header = true

	public identity;
	public token;
	public entidad;
	public entidades = []
	public entidadesTemp = []
	public entidadesLength
	public entidadsel = this._entidadService.getEntidad();
	public update_reg = false;
	public rutas = [];
	public notificacion = [];
	public closeResult: string;
	public ambiente = Global.url;
	public detalle;
	public cant_notificacion;
	public logo = document.location.origin + "/assets/img/logo.png";
	public sub;
	public user_img = document.location.origin + "/assets/img/user/user-13.jpg";
	public env = environment.env
	public userData
	public profilePhotoURL
	userDataWithPhotoSubscription: Subscription;
	entitiesSubscription: Subscription;

	mobileSidebarToggle() {
		this.toggleMobileSidebar.emit(true);
	}
	mobileRightSidebarToggle() {
		this.toggleMobileRightSidebar.emit(true);
	}
	toggleSidebarRight() {
		this.toggleSidebarRightCollapsed.emit(true);
	}

	mobileTopMenuToggle() {
		this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings
			.pageMobileTopMenuToggled;
	}

	mobileMegaMenuToggle() {
		this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings
			.pageMobileMegaMenuToggled;
	}

	ngOnDestroy() {
		this.pageSettings.pageMobileTopMenuToggled = false;
		this.pageSettings.pageMobileMegaMenuToggled = false;
		this.userDataWithPhotoSubscription.unsubscribe();
		this.entitiesSubscription.unsubscribe();

	}

	constructor(
		private renderer: Renderer2,
		private _router: Router,
		private _userService: UserService,
		private _entidadService: EntidadService,
		private _route: ActivatedRoute,
		private _notificacionService: NotificacionService,
		private _localService: LocalService,
		private modalService: NgbModal,
		private authMicrosoft: MicrosoftAuthService,
		private _sharingService: SharingService
	) {
		// this.identity = this._userService.getIdentity();
		// if (this.identity == null) {
		// } else {
		// 	let mostrar_contenido =
		// 		this._localService.getJsonValue("usar-aqui")
		// 	if (mostrar_contenido == true) {
		// 		this.usarAqui()
		// 	}
		// 	this.getNotificacion();
		// 	this.token = this._userService.getToken();
		// 	this.entidad = new Entidad("", 0, "", "", "", 0);
		// 	this.entidad.campo = this.identity.campo;

		// 	this._userService.privilegiosRuta().subscribe(
		// 		(response) => {
		// 			this.rutas = response;
		// 			this._localService.setJsonValue("rutas", JSON.stringify(this.rutas));
		// 		},
		// 		(error) => {
		// 			if (
		// 				error.error.message == "El Token ha expirado" ||
		// 				error.error.message == "El Token no es valido"
		// 			) {

		// 				this.logout();

		// 			}
		// 		}
		// 	);

		// 	//observable
		// 	this.sub = interval(30000)
		// 		.subscribe((val) => {
		// 			//console.log("interbal")
		// 			this.token = this._userService.getToken()
		// 			this.actualizarToken()
		// 		});
		// 	this.actualizarToken()
		// 	this._entidadService.getEntidadSel()
			// this.entidades = JSON.parse(this._localService.getJsonValue('entidades'))
			

		// }
	}

	// actualizarToken() {
	// 	this.token = this._userService.getToken()
	// 	if (this.token != null) { // Este if es para que se tome en cuenta el ultimo inicio de sesion para todas las sesiones
	// 		this._wsService.emit("token", this.token)
	// 	}
	// }

	getClassLevelEnv() {
		let classEnv = this.env == 'Testing' ? 'label label-warning' : 'label label-primary'

		return classEnv
	}

	ngOnInit() {
		this.userDataWithPhotoSubscription = this._userService.combinedData$.subscribe(data => {
			if(data){
				this.userData = data.userData
				this.profilePhotoURL = data.photoURL
			}
		});
		this.entitiesSubscription = this._sharingService.sharingEntities.subscribe(entities => {
			// console.log(entities)
			if(entities){
				this.entidades = entities
				this.entidadesTemp = this.entidades
			}
		})
	}

	logout() {
		this.authMicrosoft.clearSesion()
		this.authMicrosoft.logout().subscribe(res => {
			let a = document.createElement('a')
			a.href = res.url
			a.click()
		})
	}


	// cerrar_UsarAqui() {
	// 	//console.log("entre a Cerrar y usar aqui")
	// 	this._wsService.listen("cerrar").subscribe((data) => {
	// 		//		console.log("emit cerrar")
	// 		let id_usuario = this._localService.getJsonValue("id_usuario")
	// 		let dispositivo = this._localService.getJsonValue("dispositivo")
	// 		// if (data[0] == id_usuario) {
	// 		// 	if (data[1] == "" || data[1] == dispositivo) {
	// 		// 		Swal.close()
	// 		// 		localStorage.clear()
	// 		// 		this._router.navigate(["/login"])
	// 		// 	}
	// 		// }
	// 	})

	// 	this._wsService.listen("usar-aqui").subscribe((response) => {
	// 		let data = response
	// 		//console.log("entro")
	// 		//console.log(data)
	// 		let id_usuario = this._localService.getJsonValue("id_usuario")
	// 		let dispositivo = this._localService.getJsonValue("dispositivo")

	// 		if (data[0] == id_usuario && data[1] == dispositivo) {
	// 			this._localService.setJsonValue("usar-aqui", true)
	// 			this.usarAqui()
	// 		}
	// 	})

	// }

	// async usarAqui() {
	// 	this.mostrar_header = false
	// 	this.mostrar.emit(false)
	// 	try {
	// 		const alert = await Swal.fire({
	// 			html: '<p>Ud a estado trabajando en otra ventana. Haga clic en USAR AQUÍ para habilitar esta ventana</p>',
	// 			showCancelButton: true,
	// 			allowOutsideClick: false,
	// 			cancelButtonText: "Cerrar",
	// 			confirmButtonText: "USAR AQUÍ"
	// 		}).then((result) => {
	// 			return result;
	// 		})

	// 		await this.resultUsarAqui(alert)
	// 		//console.log(alert)

	// 	} catch (e) {
	// 		console.error("error: ", e)
	// 	}
	// 	if (this.mostrar_header) {

	// 		this.mostrar.emit(true)
	// 		this._localService.setJsonValue("usar-aqui", false)


	// 	}
	// 	//console.log(this.mostrar_header)
	// }
	// async resultUsarAqui(alert): Promise<any> {

	// 	if (alert.value) {

	// 		return new Promise((resolve, reject) => {
	// 			this._userService.switchSesion("seguir").subscribe(
	// 				response => {
	// 					var result = response
	// 					this.mostrar.emit(true)
	// 					this._localService.setJsonValue("usar-aqui", false)

	// 					this.mostrar_header = true
	// 					if (response.update_reg) {
	// 						this.logout()
	// 					}
	// 					resolve(this.mostrar_header)
	// 				}, error => {
	// 					reject(error)
	// 				}
	// 			)
	// 		})
	// 	} else {
	// 		return new Promise((resolve, reject) => {

	// 			this._userService.switchSesion("cerrar").subscribe(
	// 				async response => {
	// 					this.mostrar.emit(true)
	// 					this.mostrar_header = true
	// 					this._localService.setJsonValue("usar-aqui", false)
	// 					if (response.update_reg) {
	// 						localStorage.clear()
	// 						this._router.navigate(["/login"])
	// 					}
	// 					resolve(this.mostrar_header)
	// 				}, error => {
	// 					reject(error)
	// 				}
	// 			)
	// 		})
	// 	}



	// }

	// ngDoCheck() {
	// 	this.identity = this._userService.getIdentity();
	// }



	cambiaentidad(codigo) {
		this._sharingService.sharingEntityData = codigo
		this._entidadService.cambiarEntidadSel(codigo).subscribe(res => {})
	}

	cambia() {
		this._localService.getJsonValue("entidad");
	}

	open(content, modal) {
		this.detalle = modal;
		// this.marcarLeido(this.detalle);

		this.modalService
			.open(content, { windowClass: "modal-holder", size: "md" })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}

	filtrarEntidades(event) {
		const val = event.target.value.toLowerCase()
		// console.log(this.temp)
		const temp = this.entidadesTemp.filter(function (d) {
		  let dataFilterer
		  if ((d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val) == false) {
			if ((d.nombre.toLowerCase().indexOf(val) !== -1 || !val) == false) {
		  if ((d.grupo.toLowerCase().indexOf(val) !== -1 || !val) == false) {
			if ((d.campo.toLowerCase().indexOf(val) !== -1 || !val) == false) {

			} else {
			  dataFilterer = d.campo.toLowerCase().indexOf(val) !== -1 || !val
			}
		  } else {
			dataFilterer = d.grupo.toLowerCase().indexOf(val) !== -1 || !val
		  }
			} else {
		  dataFilterer = d.nombre.toLowerCase().indexOf(val) !== -1 || !val
			}
		  } else {
			dataFilterer = d.codigo.toString().toLowerCase().indexOf(val) !== -1 || !val
		  }
		  return dataFilterer;
		})
		this.entidades = temp
	  }



	getNotificacion() {
		/*
		   this._notificacionService.getNotificaciones().subscribe((response) => {
	  //console.log(response);
	  this.notificacion = [];
	  this.notificacion = response;
	  this.cant_notificacion = 0;
	  const usuario = JSON.parse(this._localService.getJsonValue("identity"));
  
	  for (let i = 0; i < this.notificacion.length; i++) {
	  let notificacion = this.notificacion[i];
  
	  for (let nl = 0; nl < notificacion.destinatarios.length; nl++) {
	  const leidos = notificacion.destinatarios[nl];
  
	  if (leidos.id == usuario[0]._id) {
	  notificacion.index = nl;
	  if (!leidos.leido) this.cant_notificacion++;
	  break;
	  }
	  }
	  }
  
	  this.notificacion.sort((a, b) => {
	  if (a.destinatarios[a.index].leido && !b.destinatarios[b.index].leido)
	  return 1;
	  if (b.destinatarios[b.index].leido && !a.destinatarios[a.index].leido)
	  return -1;
	  return 0;
	  });
	  this.notificacion.sort((a, b) => {
	  if (
	  a.destinatarios[a.index].leido &&
	  b.destinatarios[b.index].leido &&
	  a.fecha > b.fecha
	  )
	  return -1;
	  if (
	  a.destinatarios[a.index].leido &&
	  b.destinatarios[b.index].leido &&
	  b.fecha > a.fecha
	  )
	  return 1;
	  if (
	  !b.destinatarios[b.index].leido &&
	  !a.destinatarios[a.index].leido &&
	  a.fecha > b.fecha
	  )
	  return -1;
	  if (
	  !b.destinatarios[b.index].leido &&
	  !a.destinatarios[a.index].leido &&
	  b.fecha > a.fecha
	  )
	  return 1;
	  return 0;
	  });
	  });*/
	}

	// marcarLeido(n) {
	// 	this._notificacionService.marcarLeido(n._id).subscribe((response) => {
	// 		this.notificacion = response;
	// 		this.cant_notificacion = 0;
	// 		const usuario = JSON.parse(this._localService.getJsonValue("identity"));

	// 		for (let i = 0; i < this.notificacion.length; i++) {
	// 			let notificacion = this.notificacion[i];

	// 			for (let nl = 0; nl < notificacion.destinatarios.length; nl++) {
	// 				const leidos = notificacion.destinatarios[nl];

	// 				if (leidos.id == usuario[0]._id) {
	// 					notificacion.index = nl;
	// 					if (!leidos.leido) this.cant_notificacion++;
	// 				}
	// 			}
	// 		}

	// 		this.notificacion.sort((a, b) => {
	// 			if (a.destinatarios[a.index].leido && !b.destinatarios[b.index].leido)
	// 				return 1;
	// 			if (b.destinatarios[b.index].leido && !a.destinatarios[a.index].leido)
	// 				return -1;
	// 			return 0;
	// 		});
	// 		this.notificacion.sort((a, b) => {
	// 			if (
	// 				a.destinatarios[a.index].leido &&
	// 				b.destinatarios[b.index].leido &&
	// 				a.fecha > b.fecha
	// 			)
	// 				return -1;
	// 			if (
	// 				a.destinatarios[a.index].leido &&
	// 				b.destinatarios[b.index].leido &&
	// 				b.fecha > a.fecha
	// 			)
	// 				return 1;
	// 			if (
	// 				!b.destinatarios[b.index].leido &&
	// 				!a.destinatarios[a.index].leido &&
	// 				a.fecha > b.fecha
	// 			)
	// 				return -1;
	// 			if (
	// 				!b.destinatarios[b.index].leido &&
	// 				!a.destinatarios[a.index].leido &&
	// 				b.fecha > a.fecha
	// 			)
	// 				return 1;
	// 			return 0;
	// 		});
	// 	});
	// }

	// eliminar(id) {
	// 	this._notificacionService.eliminar(id).subscribe((response) => {
	// 		this.notificacion = response;
	// 		this.cant_notificacion = 0;
	// 		const usuario = JSON.parse(this._localService.getJsonValue("identity"));

	// 		for (let i = 0; i < this.notificacion.length; i++) {
	// 			let notificacion = this.notificacion[i];

	// 			for (let nl = 0; nl < notificacion.destinatarios.length; nl++) {
	// 				const leidos = notificacion.destinatarios[nl];

	// 				if (leidos.id == usuario[0]._id) {
	// 					notificacion.index = nl;
	// 					if (!leidos.leido) this.cant_notificacion++;
	// 				}
	// 			}
	// 		}

	// 		this.notificacion.sort((a, b) => {
	// 			if (a.destinatarios[a.index].leido && !b.destinatarios[b.index].leido)
	// 				return 1;
	// 			if (b.destinatarios[b.index].leido && !a.destinatarios[a.index].leido)
	// 				return -1;
	// 			return 0;
	// 		});
	// 		this.notificacion.sort((a, b) => {
	// 			if (
	// 				a.destinatarios[a.index].leido &&
	// 				b.destinatarios[b.index].leido &&
	// 				a.fecha > b.fecha
	// 			)
	// 				return -1;
	// 			if (
	// 				a.destinatarios[a.index].leido &&
	// 				b.destinatarios[b.index].leido &&
	// 				b.fecha > a.fecha
	// 			)
	// 				return 1;
	// 			if (
	// 				!b.destinatarios[b.index].leido &&
	// 				!a.destinatarios[a.index].leido &&
	// 				a.fecha > b.fecha
	// 			)
	// 				return -1;
	// 			if (
	// 				!b.destinatarios[b.index].leido &&
	// 				!a.destinatarios[a.index].leido &&
	// 				b.fecha > a.fecha
	// 			)
	// 				return 1;
	// 			return 0;
	// 		});
	// 	});
	// }

	// irRuta(notificacion) {
	// 	this.modalService.dismissAll();

	// 	if (notificacion.ruta) {
	// 		this.cambiaentidad(notificacion.entidad);
	// 		this._router.navigate(["/" + notificacion.ruta]);
	// 	}
	// }

	// eliminarTodo() {
	// 	this._notificacionService.eliminarTodo().subscribe((response) => {
	// 		this.notificacion = response;
	// 		this.cant_notificacion = 0;
	// 	});
	// }
}
