import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global } from './global';
import { UserService } from './user.service';
import { LocalService } from './localService';
import { TraePagosParamsModel } from '../models/aps/traePagosParams.model';
import { RegistroPagoResponseModel } from '../models/aps/registro_pago_response.model';
import { RegistroPago } from '../models/aps/registro_pago';
import { RegistroPagoParamsModel } from '../models/aps/registro_pago_params.model';
import { SharingService } from './sharing.service';


@Injectable()

export class ApsService {
  public url: string;
  public identity;
  public token;
  public entidad;

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private _localService: LocalService,
    private _sharingService: SharingService

  ) {
    this.url = Global.url;
    this._sharingService.sharingIdentity.subscribe(data => {
      this.identity = data
    })
    this.token = this._userService.getToken();
    //this.identity = this._userService.getIdentity();
    //this.token = this._userService.getToken();

  }

  traePagos(params: TraePagosParamsModel): Observable<any> {

    //entidad.campo = this.identity.campo;
    //console.log(params); return false;
    //definir las cabeceras
    console.log(params)
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-pagos-aps',
      params, { headers: headers })
    return resultado;

  }
  crearPago(datos): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = JSON.stringify(datos);
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'aps-crear-pago',
      params, { headers: headers })
    return resultado;

  }
  actualizarRegistroPagos(id): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      pago: id
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'aps-actualizar-registro-pagos',
      params, { headers: headers })
    return resultado;

  }
  actualizarRegistroPago(registro): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = JSON.stringify(registro)
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + '/actualiza-registro-pago-aps',
      params, { headers: headers })
    return resultado;

  }
  traeRegistrosPago(params: RegistroPagoParamsModel): Observable<RegistroPagoResponseModel<RegistroPago>> {
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post<RegistroPagoResponseModel<RegistroPago>>(this.url + 'trae-registros-pago-aps',
      params, { headers: headers })
    return resultado;
  }
  eliminarRegistroPago(id): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      pago: id,
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'eliminar-registro-pago-aps',
      params, { headers: headers })
    return resultado;

  }
  eliminarRegistrosPagosSeleccionados(id, registros): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      pago: id,
      registros: registros
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'eliminar-registros-pagos-seleccionados-aps',
      params, { headers: headers })
    return resultado;

  }
  traeBancos(): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      //id: periodo
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-bancos-aps',
      params, { headers: headers })
    return resultado;

  }
  traeTipoCuentas(): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      //id: periodo
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-tipo-cuentas-aps',
      params, { headers: headers })
    return resultado;

  }
  traeTiposMetodosPago(periodo): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      id: periodo
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-tipo-metodos-pago-aps',
      params, { headers: headers })
    return resultado;

  }
  traeTiposPago(): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      //id: periodo
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-tipos-pago-aps',
      params, { headers: headers })
    return resultado;

  }
  traeTiposServicio(): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      //id: periodo
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'trae-tipos-servicio-aps',
      params, { headers: headers })
    return resultado;

  }
  generarArchivoPago(id, pagina): Observable<any> {

    //entidad.campo = this.identity.campo;
    let params = {
      pago: id,
      page_number: pagina
    };
    //console.log(params); return false;
    //definir las cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
    //Hacer peticion ajax
    var resultado = this._http.post(this.url + 'generar-archivo-pago-aps',
      params, { headers: headers })
    return resultado;

  }


}
