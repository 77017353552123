import { Component, OnInit } from '@angular/core';
import { NotificacionService } from '../../../services/notificaciones.service'
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
  providers:[NotificacionService, UserService]
})

export class NotificacionesComponent implements OnInit {

  public descripcion = "Administrar"
  public titulo = "Notificaciones"
  public tags = [];
  public filtros = false;
  public notificacion = {
    asunto: "",
    mensaje: "",
    privilegios : {}
  }
  public todos = false
  public privilegios
  public token;
  constructor(
    private _notificacionService: NotificacionService
    ) {

    }

  ngOnInit() {
    this.traerPrivilegios()

  }

  traerPrivilegios() {
    this._notificacionService.traerPrivilegios().subscribe(
      response => {
        this.privilegios = response
        for (let p = 0; p < this.privilegios.length; p++) {
          this.privilegios[p].seleccionado = false
        }
      }
    )
  }

  agrega(){
    let ultEntidad = this.tags.length
    this._notificacionService.verificarEntidad(this.tags[ultEntidad - 1].value).subscribe(
      response => {
        if(!response){
          Swal.fire({
            title: "Error",
            text: "No se encontro la entidad: " + this.tags[ultEntidad - 1].value,
            icon: 'warning',
          }).then((value) => {
            this.tags.pop()
          })
        }
      }
    )
  }

  // Selecciona los privilegios a los cuales se mandara la notificacion
  agregarPrivilegio(level){
    if(level != "todos"){
      let sel = []
      let control = []

      for (let l = 0; l < this.privilegios.length; l++) {

        if(level == this.privilegios[l].nombre){
          (this.privilegios[l].seleccionado == true) ? (this.privilegios[l].seleccionado = false) : (this.privilegios[l].seleccionado = true)
        }
        sel.push(this.privilegios[l].seleccionado)
        control.push(true)

      }

      (sel.toString() == control.toString()) ? this.todos = true : this.todos = false

    }else{
      let sel = []
      let control = []
      for (let l = 0; l < this.privilegios.length; l++) {
        sel.push(this.privilegios[l].seleccionado)
        control.push(true)
      }

      if(sel.toString() == control.toString()){
        for (let l = 0; l < this.privilegios.length; l++) {
          this.privilegios[l].seleccionado = false
        }
        this.todos = false
      }else{
        for (let l = 0; l < this.privilegios.length; l++) {
          this.privilegios[l].seleccionado = true
        }
        this.todos = true
      }

    }
  }


  enviar(form){
    //console.log(form)
    let error = false
    if(!this.filtros){
      this.tags = []
    }

    if(this.filtros && this.tags.length == 0 && !error){
      Swal.fire({
        title: "Advertencia",
        text: "¿Esta seguro de continuar sin aplicar un filtro?",
        icon: 'warning',
        showCancelButton: true
      }).then((value) => {
        if(value.dismiss){
          error = true
        }else{
          error = false
        }
        console.log(error)
        if(!error){

          this._notificacionService.nuevaNotificacion(this.notificacion, this.privilegios, this.tags).subscribe(
            response => {
              // this._notificacionService.nueva_notificacion.trigger("client-recepcion-notificacion", response)
              Swal.fire({
                title: "Enviado",
                text: "Notificación enviada correctamente",
                icon: 'success',
              })
              form.reset()
              this.tags = []
              this.filtros = false
              for (let l = 0; l < this.privilegios.length; l++) {
                this.privilegios[l].seleccionado = false
                this.todos = false
              }

            }
          )
        }
      })
    }

    let sel = []
    let control = []
    for (let l = 0; l < this.privilegios.length; l++) {
      sel.push(this.privilegios[l].seleccionado)
      control.push(false)
    }
    //console.log(error)
    if(sel.toString() == control.toString()){
      Swal.fire({
        title: "Advertencia",
        text: "Debe seleccionar al menos un privilegio",
        icon: 'warning',
      })
    }else if(!error){
      //console.log("entré")
      this._notificacionService.nuevaNotificacion(this.notificacion, this.privilegios, this.tags).subscribe(
        response => {
          //console.log(response)
          Swal.fire({
            title: "Enviado",
            text: "Notificación enviada correctamente",
            icon: 'success',
          })
          form.reset()
          this.tags = []
          this.filtros = false
          for (let l = 0; l < this.privilegios.length; l++) {
            this.privilegios[l].seleccionado = false
            this.todos = false
          }
        }
      )
    }
  }
}

