import { environment } from './../../environments/environment';

const localhost = {
  type: 'local',
  url:  'http://localhost:3700' + "/api-declaracion/",
  url_user:  'http://localhost:3700' + "/api-users/",
  url_jsreport: 'http://localhost:5488/api/report',
  url_seguros: 'http://localhost:3700' +'/api-seguros/',
  url_fpv: 'http://localhost:3700' +'/api-fpv/',
  url_report: 'http://localhost:3700' +'/api-reports/',
	url_socket: 'http://localhost:3000',
  url_auth: 'http://localhost:3700/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
}

const dev = {
  type: 'dev',
  url: 'https://personas-dev.adventistas.cl' + "/api-declaracion/",
  url_user:  'https://personas-dev.adventistas.cl' + "/api-users/",
  url_jsreport: 'https://api-jsreport.adventistas.cl/api/report',
  url_seguros: 'https://personas-dev.adventistas.cl' +'/api-seguros/',
  url_fpv: 'https://personas-dev.adventistas.cl' +'/api-fpv/',
  url_report: 'https://personas-dev.adventistas.cl' +'/api-reports/',
	url_socket: 'https://devsocketpersonas.adventistas.cl',
  url_auth: 'https://personas-dev.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
}

const test = {
  type: 'test',
  url: 'https://personas-staging.adventistas.cl/api-declaracion/',
  url_user:  'https://personas-staging.adventistas.cl' + "/api-users/",
  url_jsreport: 'https://api-jsreport.adventistas.cl/api/report',
  url_seguros:'https://personas-staging.adventistas.cl/api-seguros/',
  url_fpv:'https://personas-staging.adventistas.cl/api-fpv/',
  url_report:'https://personas-staging.adventistas.cl/api-reports/',
  url_socket: 'https://testsocketpersonas.adventistas.cl/',
  url_auth: 'https://personas-staging.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
}

const prod = {
  type: 'prod',
  url: 'https://personas.adventistas.cl/api-declaracion/',
  url_user:  'https://personas.adventistas.cl' + "/api-users/",
  url_jsreport: 'https://api-jsreport.adventistas.cl/api/report',
  url_seguros:'https://personas.adventistas.cl/api-seguros/',
  url_fpv:'https://personas.adventistas.cl/api-fpv/',
  url_report:'https://personas.adventistas.cl/api-reports/',
  url_socket: 'https://socketpersonas.adventistas.cl/',
  url_auth: 'https://personas.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
}

if (environment.env != 'Production'){
	console.log("Enviroment: " + environment.env)
}
export const Global = environment.env == 'Testing' ? test : (environment.env== 'Production'?prod:(environment.env== 'Development'?dev:localhost))

