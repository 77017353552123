
<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<panel variant="iatuch" title="{{ titulo_panel }}">
<div class="row">
  <div class="form-inline col-md-8">

    <label>Evento:&nbsp;&nbsp;</label>
    <select *ngIf="lista_eventos" name="eventos" [(ngModel)] = 
		   "seleccionado" class="form-control" placeholder ="Seleccione..." 
				  (change) = "seleccionarEvento(seleccionado)">
      <option value="" disabled selected >Seleccione...</option>
      <option [ngValue]="ev" *ngFor="let ev of lista_eventos">{{ev.nombre}} 
      </option>
    </select>
    &nbsp;&nbsp;
    <label *ngIf="evento">Rut:&nbsp;&nbsp; </label>
    <input *ngIf="evento" type="text" class="form-control" name="rut" 
							   [(ngModel)]="rut">		    
    &nbsp;&nbsp;
    <button (click) ="presenteDelegado()" *ngIf="rut" placement="top" 
						      ngbTooltip="Presente" 
						      type="button" 
						      class="btn 
							     btn-primary"><i 
						      class="fa 
							     fa-check"></i></button>
  </div>
  <div class="col-md-12" *ngIf="estado_consulta_delegado == false">
    <label class="text-warning"><i class="fa fa-exclamation-circle"></i> 
  {{mensaje_consulta_delegado}}</label>
  </div>
</div>

</panel>
<panel variant="iatuch" title="Datos del Delegado" *ngIf = "delegado">
<div class="row">
  <table class="table">
    <tbody>
      <tr>
	<td rowspan="3"> <img width="128px" height="128px" *ngIf="delegado.foto 
	== 'NULL'" [src]="user_img" > <img *ngIf="delegado.foto != 'NULL'" 
					    [src]="delegado_img" >
	</td>
	<td>Nombre: </td>
	<td>{{delegado.nombre}}</td>
	<td>Rut:</td>
	<td>{{delegado.rut}}</td>
      </tr>
      <tr>
	<td>Iglesia: </td>
	<td>{{delegado.iglesia}}</td>
	<td>Distrito: </td>
	<td>{{delegado.distrito}}</td>
      </tr>
    </tbody>

  </table>
</div>

</panel>
