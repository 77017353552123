import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharingService {
    private sharingObservableIdentity: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
    private sharingObservableEntity: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
    private sharingObservableEntities: BehaviorSubject<[]> = new BehaviorSubject<[]>([] );
    private sharingObservableToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private sharingObservableRefreshToken: BehaviorSubject<''> = new BehaviorSubject<''>('');
    private sharingObservableLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('es-CL');
    private sharingObservableMenu: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
    private sharingObservableRole: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
    private sharingObservableMenuPath: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

    get sharingRefreshToken(){
        return this.sharingObservableRefreshToken.asObservable();
    }

    set sharingRefreshTokenData(data){
        this.sharingObservableRefreshToken.next(data);
    }

    get sharingIdentity(){
        return this.sharingObservableIdentity.asObservable();
    }

    set sharingIdentityData(data){
        this.sharingObservableIdentity.next(data);
    }

    get sharingToken(){
        return this.sharingObservableToken.asObservable();
    }

    set sharingTokenData(data){
        this.sharingObservableToken.next(data);
    }

    get sharingEntity(){
        return this.sharingObservableEntity.asObservable();
    }

    set sharingEntityData(data){
        this.sharingObservableEntity.next(data);
    }

    get sharingEntities(){
        return this.sharingObservableEntities.asObservable()
    }

    set sharingEntitiesData(data){
        this.sharingObservableEntities.next(data);
    }

    get sharingLanguage(){
        return this.sharingObservableLanguage.asObservable()
    }

    set sharingLanguageData(data){
        this.sharingObservableLanguage.next(data);
    }

    get sharingRole(){
        return this.sharingObservableRole.asObservable()
    }

    set sharingRoleData(data){
        this.sharingObservableRole.next(data);
    }
     
    get sharingMenu(){
        return this.sharingObservableMenu.asObservable()
    }

    set sharingMenuData(data){
        this.sharingObservableMenu.next(data);
    }

    get sharingMenuPath(){
        return this.sharingObservableMenuPath.asObservable()
    }

    set sharingMenuDataPath(data){
        this.sharingObservableMenuPath.next(data);
    }

  

}
