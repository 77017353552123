export class ReporteEvento {
	constructor(
		public total: number,
		public presentes: number,
		public ausentes: number,
		public porc_presentes: number,
		public porc_ausentes: number,
		public ultimo: JSON
	) {}
}
