import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Global} from './global';
import {UserService} from './user.service';
import {LocalService} from './localService';


@Injectable()

export class MovilizadosService {
	public url: string;
	public identity;
	public token;
	public entidad;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _localService: LocalService
	) {
		this.url = Global.url;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();

	}
	traeMinisterios(): Observable<any> {

		//entidad.campo = this.identity.campo;
		let params = {};
		//console.log(params); return false;
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url + 'trae-ministerios-movilizados',
			params, {headers: headers})
		return resultado;

	}
	traePeriodo(periodo): Observable<any> {

		//entidad.campo = this.identity.campo;
		let params = {
		  id: periodo
		};
		//console.log(params); return false;
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url + 'trae-periodo-movilizados',
			params, {headers: headers})
		return resultado;

	}
	enviarDocumentacionPeriodo(documentacion):Observable<any>{
	  let params = documentacion
	  let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
	  //Hacer peticion ajax
	  var resultado = this._http.post(this.url + 'enviar-documentacion-periodo-movilizados',
					  params, {headers: headers})
	  return resultado;

	}
	traeReportePeriodos(parametros):Observable<any>{
	  let params = parametros
	  let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
	  //Hacer peticion ajax
	  var resultado = this._http.post(this.url + 
					  'reporte-periodo-distritos-movilizados',
					  params, {headers: headers})
	  return resultado;

	}




}
