import { Component, OnInit } from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import * as global from '../../../config/globals';

@Component({
  selector: 'app-estadisticas-movilizados',
  templateUrl: './estadisticas-movilizados.component.html',
  styleUrls: ['./estadisticas-movilizados.component.css']
})
export class EstadisticasMovilizadosComponent implements OnInit {
  pageSettings = pageSettings;
  global=global
  chartData
  chartColor

  public titulo = "Estadisticas";
  public descripcion ="Movilizados";
  public icono = "fas fa-chart-line fa-fw";
  public titulo_panel = "Estadisticas Misión Sur Metropolitana Mayo 2021"
  public legend = "Distritos"
  constructor() { }

  ngOnInit(): void {
    this.chartData=[
      {
	"name":"El Bosque",
	"series":[
	  {
	    "value":0,
	    "name":"Enero 2021"
	  },
	  {
	    "value":1,
	    "name":"Febrero 2021"
	  },
	  {
	    "value":3,
	    "name":"Marzo 2021"
	  },
	  {
	    "value":2,
	    "name":"Abril 2021"
	  },
	  {
	    "value":0,
	    "name":"Mayo 2021"
	  }
	]
      },
      {"name":"Puente Alto Poniente", "series":[{"value":2,"name":"Enero 2021"},{"value":4,"name":"Febrero 2021"},{"value":2,"name":"Marzo 2021"},{"value":2,"name":"Abril 2021"},{"value":3,"name":"Mayo 2021"}]}
    ]
    this.chartColor = { domain: [global.COLOR_BLUE, global.COLOR_GREEN, global.COLOR_PURPLE, global.COLOR_BLACK] }
  }

}
