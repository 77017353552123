import { Pipe, PipeTransform } from '@angular/core';

    @Pipe({
        name: 'thousandsPipe'
    })

export class ThousandsPipe implements PipeTransform {

    public transform(value: any) {
        return value.toString()
        .replace(".","")
        .replace(".","")
        .replace(".","")
        .replace(/(\d+)(\d{3})(\d{3})(\d{3})$/ ,"$1.$2.$3.$4" )
        .replace(/(\d+)(\d{3})(\d{3})$/ ,"$1.$2.$3" )
        .replace(/(\d+)(\d{3})$/ ,"$1.$2")
    }
}
