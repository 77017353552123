import { Component, OnInit } from '@angular/core';
import { Ministerio } from '../../../models/movilizados/ministerio'
import { Periodo } from '../../../models/movilizados/periodo'
import {UserService} from '../../../services/user.service'
import {MovilizadosService} from '../../../services/movilizados.service'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reporte-movilizados',
  templateUrl: './reporte-movilizados.component.html',
  styleUrls: ['./reporte-movilizados.component.css'],
  providers:[UserService, MovilizadosService]
})
export class ReporteMovilizadosComponent implements OnInit {
  public titulo = "Reporte"
  public icono = "fa fa-list-ul"
  public descripcion = "Movilizados"
  public ministerios : Array<Ministerio>
  public ministerio: Ministerio
  public periodos : Array<Periodo>
  public MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  public registros = []
  public closeResult: string
  constructor(

    private _movilizadosService: MovilizadosService,                     
    private _userService: UserService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.traeMinisterios()
    this.traePeriodos()
  }

  // FUNCIONES DE MODAL

  open(content ) {
    let size = "sm"
    let modal_class = {
      windowClass : "modal-holder",   size: size
    }
    this.modalService.open(content, modal_class).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  //=====================================================================
  traeMinisterios(){
    //console.log(this.lista_ministerios)
    this._movilizadosService.traeMinisterios().subscribe(
      response => {
	//response.Ministerios.forEach(l => l.registros.forEach(r => r.mensaje = "null"))

	//	this.lista_ministerios = response.Ministerios
	this.ministerios = response.Ministerios
	this.ministerio = this.ministerios[0]
	/*this.registros = []
	  for(let p=0; p < this.ministerio.preguntas.length; p++){
	  let pregunta = this.ministerio.preguntas[p]
	  for(let r=0; r < pregunta.registros.length; r++){
	  let registro = pregunta.registros[r]
	  this.registros.push(registro)

	  }

	  }*/

	//console.log(this.ministerios)
	//console.log(this.ministerio)
	//console.log(this.registros)

      },
      error => {
	console.log(error)
      }


    )


  }
  traePeriodos(){
    //console.log(this.lista_ministerios)
    let parametros = {}
    this._movilizadosService.traeReportePeriodos(parametros).subscribe(
      response => {
	//response.Ministerios.forEach(l => l.registros.forEach(r => r.mensaje = "null"))

	//	this.lista_ministerios = response.Ministerios
	this.periodos = response.periodos


	//console.log(this.periodos)

      },
      error => {
	console.log(error)
      }


    )


  }
  traeMes(fecha){
    fecha = new Date(fecha)
    return this.MESES[ fecha.getMonth() ] + "/" + fecha.getFullYear()

  }
  cambiarEnfasis(enfasis){
    this.ministerio = this.ministerios.find(m => m._id == enfasis)
    //console.log(enfasis)
  }
}
