
<link
rel="stylesheet"
href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
/>
<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
    <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
    <li class="breadcrumb-item active">Pagina</li>
</ol>
<!-- end breadcrumb -->
<!--################################################COntenido############################################################-->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin row -->
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-iatuch ">
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">
                </div>
                <h4 class="panel-title"><i class="{{icono}}"></i> {{titulo}} </h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body ">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="btn-group">
                            <button id="bt_car_ind" class="btn btn-green" (click)="carga('individual')">Nuevo
                                Usuario</button>
                            <button id="bt_car_mas" class="btn btn-white" (click)="carga('masiva')">Carga
                                Masiva</button>
                            <button id="bt_car_mas" class="btn btn-white" (click)="carga('administracion')">Administrar
                                Usuarios</button>
                            &nbsp;&nbsp;
                            <a href="./assets/plantillas/instructivo_gestion_usuarios.pdf" download="Instructivo gestión de Usuarios"><input type="button" id="bt_ins" class="btn btn-white" value="Instructivo" /></a>
                        </div>
                    </div>
                    <br>
                </div>
                <!-- Formulario para solicitud de ingreso unico de usuario -->
                <div id="formCargaIndividual" *ngIf="tipoCarga == 'individual'">
                    <form #admUsuariosFormUni="ngForm" (ngSubmit)="onSubmit2(admUsuariosFormUni)">

                        <div class="form-row">
                            <div class="form-group">
                                <label class="text-info">Nombre</label>
                                <input type="text" name="nombre" class="form-control" #nombre="ngModel"
                                    placeholder="Nombre" [(ngModel)]="usuario.nombre" required>
                                <div class="invalid-feedback d-block" *ngIf="!nombre.valid && nombre.touched">Campo
                                    Requerido</div>
                            </div>
                            <div class="form-group">
                                <label class="text-info">Apellido</label>
                                <input type="text" name="apellido" class="form-control" #apellido="ngModel"
                                    placeholder="Apellido" [(ngModel)]="usuario.apellido" required>
                                <div class="invalid-feedback d-block" *ngIf="!apellido.valid && apellido.touched">Campo
                                    Requerido</div>
                            </div>
                            <div class="form-group" id="rut">
                                <label class="text-info">Rut</label>
                                <input type="text" name="rut" class="form-control" #rut="ngModel"
                                    placeholder="Ingrese el RUT" [(ngModel)]="usuario.rut" required>
                                <div class="invalid-feedback d-block" *ngIf="!rut.valid && rut.touched">Campo Requerido
                                </div>
                            </div>

                        </div>
                        <div class="form-check">
                            <label class="text-info">Entidades</label>
                            <p *ngFor="let entidad of entidades" name="entidades">
                                <input type="checkbox"
                                    (change)="$event.target.checked ? agregarEntidad(entidad) : quitarEntidad(entidad)"
                                    value="{{entidad.codigo}}" required>
                                <span class="entidadClass">{{entidad.codigo}} {{entidad.nombre}}</span>
                            </p>
                            <div class="invalid-feedback d-block" *ngIf="!entidades.checked && entidades.touched">Campo
                                Requerido</div>
                        </div>
                        <div class="form-row">
                            <div class="form-group">
                                <label class="text-info">Correo</label>
                                <div class="form-inline">
                                    <input type="text" name="email" class="form-control" #email="ngModel"
                                        placeholder="Correo" [(ngModel)]="usuario.email" required>
                                    <div class="invalid-feedback d-block" *ngIf="!email.valid && email.touched">Campo
                                        Requerido</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-info">Cargo</label>
                                <input type="text" name="cargo" class="form-control" #cargo="ngModel"
                                    placeholder="Cargo" [(ngModel)]="usuario.cargo" required>
                                <div class="invalid-feedback d-block" *ngIf="!cargo.valid && cargo.touched">Campo
                                    Requerido</div>
                            </div>
                            <div class="form-group">
                                <label class="text-info">Privilegios</label>
                                <select name="privilegios" class="form-control custom-select" #privilegios="ngModel"
                                    [(ngModel)]="usuario.privilegios" required>
                                    <option value="" disabled>Seleccione..</option>
                                    <option *ngFor="let p of listaPrivilegios;let indice=index" value="{{p.nombre}}">
                                        {{p.descripcion}}</option>
                                </select>
                                <div class="invalid-feedback d-block" *ngIf="!privilegios.valid && privilegios.touched">
                                    Campo Requerido</div>
                            </div>
                        </div>


                        <div class="form-row">
                            <button type="submit" class="btn btn-green" [disabled]="admUsuariosFormUni.invalid"
                                data-toggle="tooltip" data-placement="top" title="Enviar Solicitud">
                                <i class="fa fa-save"></i> Enviar Solicitud Nuevo Usuario</button>
                        </div>
                    </form>
                    <div *ngIf="cargando == true" class="col-md-6"><br>
                        <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando Resultados...</label>
                    </div>
                </div>

                <!-- Formulario Carga Masiva -->

                <div class="row" *ngIf="tipoCarga == 'masiva'">
                    <div class="col-md-6">
                        <form #admUsuariosForm="ngForm" (ngSubmit)="onSubmit(admUsuariosForm)">
                            <div class="col-md-12">
                                <label>Archivo: {{nombre_archivo}}</label>
                            </div>
                            <button type="button" class="btn btn-info btn-sm m-r-3" (click)="abrir()"><i class="fa fa-plus"></i><span> Añadir Archivo...</span></button>
                            <span class="btn btn-sm btn-info fileinput-button m-r-3 hide">
                                <i class="fa fa-plus"></i>
                                <span> Añadir Archivo...</span>
                                <input #archivo_solicitud type="file" id="btn_carga_masiva" (change)="fileChageEvent($event)" required>
                            </span>
                            <button type="submit" class="btn btn-sm btn-green start m-r-3" data-toggle="tooltip"
                                data-placement="top" title="Subir" [disabled]="!nombre_archivo">
                                <i class="fa fa-upload"></i></button>
                            <a href="./assets/plantillas/plantilla_usuarios.xlsx" class="btn btn-sm btn-primary"
                                data-toggle="tooltip" data-placement="top" title="Descargar Plantilla">
                                <i class="fa fa-download"></i></a>

                            <div *ngIf="cargando == true" class="col-md-6"><br>
                                <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando
                                    Resultados...</label>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Formulario para administrar los usuarios existentes -->
                <div id="formAdministracion" *ngIf="tipoCarga == 'administracion'">
                    <form #admUsuarios="ngForm" (ngSubmit)="onSubmit3(admUsuarios.value)">
                        <div class="form-row">
                            <div class="form-group">
                                <label class="text-info">Metodo de Busqueda</label>
                                <select class="form-control" name="busquedaselect" ngModel>
                                    <option value="" selected disabled>Seleccione...</option>
                                    <option value="usuario">Busqueda por usuario</option>
                                    <option value="rut">Busqueda por Rut</option>
                                </select>
                                <span class="text-danger" *ngIf="busquedaselect == ''">Debe Seleccionar un metodo de
                                    busqueda</span>
                            </div>
                            <div class="form-group">
                                <label class="text-info">Criterio de Busqueda</label>
                                <input type="text" name="busqueda" class="form-control"
                                    placeholder="Ingrese datos a buscar" ngModel>
                            </div>
                            <button type="submit" class="btn btn-info"><i class="fa fa-search"></i> Buscar</button>
                        </div>
                    </form>
                    <div *ngIf="cargando == true" class="col-md-6"><br>
                        <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando Resultados...</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="resultado == 1 && listaPlanilla.length > 0">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">
                </div>
                <h4 class="panel-title"><i class="{{icono}}"></i> {{titulo_solicitud}} </h4>
            </div>
            <div class="panel-body">
                <ng-container *ngIf="listaPlanilla else divListaUsuario">
                    <div class="row">
                        <div class="table-responsive">
                            <table id="planillas" class="tabla_planilla table table-condensed table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre Archivo</th>
                                        <th>Fecha</th>
                                        <th>Estado</th>
                                        <th>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lp of listaPlanilla;let indice=index">
                                        <td>{{indice+1}}</td>
                                        <td>{{lp.archivo.name}}</td>
                                        <td>{{lp.fecha | date:'dd/MM/yyyy'}}</td>
                                        <td><span class={{lp.estado.clase}} data-toggle="tooltip" data-placement="top"
                                                title="{{lp.estado.mensaje}}">{{lp.estado.estado}}</span>
                                        <td><button class="btn btn-info btn-xs" (click)="getSolicitudUsuario(lp._id)"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Ver usuarios de la planilla"><i class="fa fa-eye"></i></button>
                                            <!--<button class="acciones btn btn-danger btn-xs" (click) = "eliminarPlanilla(lp._id)" data-toggle="tooltip" data-placement="top" title="Eliminar planilla y los usuarios en ella"><i class="fa fa-trash"></i></button>-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="resultado == 2 && listaUsuario.length > 0">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">
                </div>
                <h4 class="panel-title"><i class="{{icono}}"></i> {{titulo_solicitud}} </h4>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="table-responsive">
                        <button class="btn btn-default" (click)="getPlanillaUsuario()" data-toggle="tooltip"
                            data-placement="top" title="Volver"><i class="fas fa-arrow-left"></i></button>
                        <table id="usuarios" class="lista_usuario table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Usuario</th>
                                    <th>Rut</th>
                                    <th>Email</th>
                                    <th>Entidades</th>
                                    <th>Cargo</th>
                                    <th>Campo</th>
                                    <th>Privilegios</th>
                                    <th>Solicitud</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="listaUsuario">
                                <tr *ngFor="let lu of listaUsuario;let indice=index">
                                    <td>{{indice+1}}</td>
                                    <td>{{lu.usuario_solicitado.nombre_usuario}}</td>
                                    <td>{{lu.usuario_solicitado.rut}}</td>
                                    <td>{{lu.usuario_solicitado.email}}</td>
                                    <td>{{lu.usuario_solicitado.entidades}}</td>
                                    <td>{{lu.usuario_solicitado.cargo}}</td>
                                    <td>{{lu.usuario_solicitado.campo_institucion}}</td>
                                    <td>{{lu.usuario_solicitado.privilegios.descripcion}}</td>
                                    <td>{{lu.estado.accion}}</td>
                                    <td><span class={{lu.estado.clase}} data-toggle="tooltip" data-placement="top"
                                            title="{{lu.estado.mensaje}}">{{lu.estado.estado}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="resultado == 3 && listaBusqueda.length > 0">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">
                </div>
                <h4 class="panel-title"><i class="{{icono}}"></i> {{titulo_solicitud}} </h4>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="table-responsive">
                        <button class="btn btn-default" (click)="getPlanillaUsuario()" data-toggle="tooltip"
                            data-placement="top" title="Volver"><i class="fas fa-arrow-left"></i></button>
                        <table id="usuarios" class="lista_usuario table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <td>Nombre Usuario</td>
                                    <td>Rut</td>
                                    <td>Entidades</td>
                                    <td>Campo</td>
                                    <td>Privilegios</td>
                                    <td>Correo</td>
                                    <td>Acciones</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="listaBusqueda">
                                <tr *ngFor="let b of listaBusqueda;let indice=index">
                                    <td>{{indice+1}}</td>
                                    <td>{{b.nombre_usuario}}</td>
                                    <td>{{b.rut}}</td>
                                    <td>{{b.entidades}}</td>
                                    <td>{{b.campo_institucion}}</td>
                                    <td>{{b.privilegios.descripcion}}</td>
                                    <td>{{b.email}}</td>
                                    <td><button (click)="editar(indice, 'Modificar'); open(modalDialog)" class="btn btn-xs btn-lime"
                                            data-toggle="modal" data-target="#editarUsuario" data-placement="top"
                                            title="Editar"><i class="fas fa-edit"></i></button>
                                        <button (click)="editar(indice, 'Clave')" class="btn btn-xs btn-warning"
                                            data-toggle="tooltip" data-placement="top"
                                            title="Reestablecer Contraseña"><i class="fas fa-key"></i></button>
                                        <button (click)="editar(indice, 'Baja')" class="btn btn-xs btn-danger"
                                            data-toggle="tooltip" data-placement="top" title="Dar de baja"><i
                                                class="fas fa-trash"></i></button>
                                        <!--<button class="btn btn-sm btn-success" (click)="open(modalDialog)">Demo</button>-->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalDialog id="modalDialog" let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Actualizar información de Usuario</h4>
        <button type="button" class="close btn btn-sm btn-white m-r-1"
            (click)="d('Cross click')"><span aria-hidden="true">&times;</span></button>


    </div>
    <div class="modal-body">
        <form #admUsuarios="ngForm" (ngSubmit)="onSubmit4(admUsuarios)">
            <div class="form-group">
                <label class="text-info">Nombre de Usuario</label>
                <input type="text" name="nombre_usuario" class="form-control" #nombre="ngModel"
                    [(ngModel)]="usuarioMod.nombre_usuario" readonly>
                <div class="invalid-feedback d-block" *ngIf="!nombre.valid && nombre.touched">Campo Requerido
                </div>
            </div>
            <div class="form-group">
                <label class="text-info">Nombre</label>
                <input type="text" name="nombre" class="form-control" #nombre="ngModel" placeholder="Nombre"
                    [(ngModel)]="usuarioMod.nombre" required>
                <div class="invalid-feedback d-block" *ngIf="!nombre.valid && nombre.touched">Campo Requerido
                </div>
            </div>
            <div class="form-group">
                <label class="text-info">Apellido</label>
                <input type="text" name="apellido" class="form-control" #apellido="ngModel" placeholder="Apellido"
                    [(ngModel)]="usuarioMod.apellido" required>
                <div class="invalid-feedback d-block" *ngIf="!apellido.valid && apellido.touched">Campo
                    Requerido</div>
            </div>
            <div class="form-group" id="rut">
                <label class="text-info">Rut</label>
                <input type="text" name="rut" class="form-control" #rut="ngModel" placeholder="Ingrese el RUT"
                    [(ngModel)]="usuarioMod.rut" required [readonly]="usuarioMod.rutValido != false">
                <div class="invalid-feedback d-block" *ngIf="!rut.valid && rut.touched">Campo Requerido</div>
            </div>
            <div class="form-group">
                <label class="text-info">Entidades</label>
                <p *ngFor="let entidad of entidades" name="entidades">
                    <input type="checkbox"
                        (change)="$event.target.checked ? agregarEntidad(entidad) : quitarEntidad(entidad)"
                        [checked]="entidad.seleccionado == true" required>
                    <span class="entidadClass">{{entidad.codigo}} {{entidad.nombre}}</span>
                </p>
                <div class="invalid-feedback d-block" *ngIf="!entidades.checked && entidades.touched">Campo
                    Requerido</div>
            </div>
            <div class="form-group">
                <label class="text-info">Correo</label>
                <input type="text" name="email" class="form-control" #email="ngModel" placeholder="Correo"
                    [(ngModel)]="usuarioMod.email" required>
                <div class="invalid-feedback d-block" *ngIf="!email.valid && email.touched">Campo Requerido
                </div>
            </div>
            <div class="form-group">
                <label class="text-info">Cargo</label>
                <input type="text" name="cargo" class="form-control" #cargo="ngModel" placeholder="Cargo"
                    [(ngModel)]="usuarioMod.cargo" required>
                <div class="invalid-feedback d-block" *ngIf="!cargo.valid && cargo.touched">Campo Requerido
                </div>
            </div>
            <div class="form-group" hidden>
                <label class="text-info">Campo</label>
                <input type="text" name="campo" class="form-control" #campo="ngModel" [(ngModel)]="usuarioMod.campo"
                    required>
                <div class="invalid-feedback d-block" *ngIf="!campo.valid && campo.touched">Campo Requerido
                </div>
            </div>
            <div class="form-group">
                <label class="text-info">Privilegios</label>
                <select name="privilegios" class="form-control custom-select"
                    (change)="cambiar_privilegio($event.target.value)">
                    <option value="{{usuarioMod.privilegiosActual.nombre}}">Actual:
                        {{usuarioMod.privilegiosActual.descripcion}}</option>
                    <option *ngFor="let p of listaPrivilegios;let indice=index" value="{{p.nombre}}"
                        [selected]="usuarioMod.privilegio == p.descripcion">{{p.descripcion}}</option>
                </select>

                <!-- <div class="invalid-feedback d-block" *ngIf="!privilegios.valid && privilegios.touched">Campo Requerido</div> -->
            </div>

            <div class="form-row">
                <button type="submit" class="btn btn-green" data-toggle="tooltip" data-placement="top"
                    title="Enviar Solicitud">
                    <i class="fa fa-paper-plane"></i> Solicitar Modificación</button>
            </div>
        </form>
        <div *ngIf="cargando == true" class="col-md-6"><br>
            <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando Resultados...</label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-white" (click)="c('Close click')">Cerrar</button>
    </div>
</ng-template>

<div class="modal fade" id="editarUsuario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Actualizar información de Usuario</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #admUsuarios="ngForm" (ngSubmit)="onSubmit4(admUsuarios)">
                    <div class="form-group">
                        <label class="text-info">Nombre de Usuario</label>
                        <input type="text" name="nombre_usuario" class="form-control" #nombre="ngModel"
                            [(ngModel)]="usuarioMod.nombre_usuario" readonly>
                        <div class="invalid-feedback d-block" *ngIf="!nombre.valid && nombre.touched">Campo Requerido
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Nombre</label>
                        <input type="text" name="nombre" class="form-control" #nombre="ngModel" placeholder="Nombre"
                            [(ngModel)]="usuarioMod.nombre" required>
                        <div class="invalid-feedback d-block" *ngIf="!nombre.valid && nombre.touched">Campo Requerido
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Apellido</label>
                        <input type="text" name="apellido" class="form-control" #apellido="ngModel"
                            placeholder="Apellido" [(ngModel)]="usuarioMod.apellido" required>
                        <div class="invalid-feedback d-block" *ngIf="!apellido.valid && apellido.touched">Campo
                            Requerido</div>
                    </div>
                    <div class="form-group" id="rut">
                        <label class="text-info">Rut</label>
                        <input type="text" name="rut" class="form-control" #rut="ngModel" placeholder="Ingrese el RUT"
                            [(ngModel)]="usuarioMod.rut" required [readonly]="usuarioMod.rutValido != false">
                        <div class="invalid-feedback d-block" *ngIf="!rut.valid && rut.touched">Campo Requerido</div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Entidades</label>
                        <p *ngFor="let entidad of entidades" name="entidades">
                            <input type="checkbox"
                                (change)="$event.target.checked ? agregarEntidad(entidad) : quitarEntidad(entidad)"
                                [checked]="entidad.seleccionado == true" required>
                            <span class="entidadClass">{{entidad.codigo}} {{entidad.nombre}}</span>
                        </p>
                        <div class="invalid-feedback d-block" *ngIf="!entidades.checked && entidades.touched">Campo
                            Requerido</div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Correo</label>
                        <input type="text" name="email" class="form-control" #email="ngModel" placeholder="Correo"
                            [(ngModel)]="usuarioMod.email" required>
                        <div class="invalid-feedback d-block" *ngIf="!email.valid && email.touched">Campo Requerido
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Cargo</label>
                        <input type="text" name="cargo" class="form-control" #cargo="ngModel" placeholder="Cargo"
                            [(ngModel)]="usuarioMod.cargo" required>
                        <div class="invalid-feedback d-block" *ngIf="!cargo.valid && cargo.touched">Campo Requerido
                        </div>
                    </div>
                    <div class="form-group" hidden>
                        <label class="text-info">Campo</label>
                        <input type="text" name="campo" class="form-control" #campo="ngModel"
                            [(ngModel)]="usuarioMod.campo" required>
                        <div class="invalid-feedback d-block" *ngIf="!campo.valid && campo.touched">Campo Requerido
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-info">Privilegios</label>
                        <select name="privilegios" class="form-control custom-select"
                            (change)="cambiar_privilegio($event.target.value)">
                            <option value="{{usuarioMod.privilegiosActual.nombre}}">Actual:
                                {{usuarioMod.privilegiosActual.descripcion}}</option>
                            <option *ngFor="let p of listaPrivilegios;let indice=index" value="{{p.nombre}}"
                                [selected]="usuarioMod.privilegio == p.descripcion">{{p.descripcion}}</option>
                        </select>

                        <!-- <div class="invalid-feedback d-block" *ngIf="!privilegios.valid && privilegios.touched">Campo Requerido</div> -->
                    </div>

                    <div class="form-row">
                        <button type="submit" class="btn btn-green" data-toggle="tooltip" data-placement="top"
                            title="Enviar Solicitud">
                            <i class="fa fa-paper-plane"></i> Solicitar Modificación</button>
                    </div>
                </form>
                <div *ngIf="cargando == true" class="col-md-6"><br>
                    <label class="text-info"><i class="fas fa-cog fa-pulse"></i> Cargando Resultados...</label>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end row -->
<!--################################################FIN COntenido############################################################-->

<!-- begin scroll to top btn-->
<a href="javascript:;" class="btn btn-icon btn-circle btn-success btn-scroll-to-top fade" data-click="scroll-top"><i
        class="fa fa-angle-up"></i></a>
<!-- end scroll to top btn -->
