import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { MicrosoftAuthService } from '../services/authentication.service';
import { SharingService } from '../services/sharing.service';
import { UserService } from '../services/user.service';
import { LocalService } from '../services/localService';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private _authMicrosoft: MicrosoftAuthService,
        private _sharingService: SharingService,
        private _userService: UserService,
        private _localService: LocalService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const expirationTime = parseInt(this._localService.getJsonValue('expires_in'));
        const expirationTime_ext = parseInt(this._localService.getJsonValue('ext_expires_in'));
        if (expirationTime) {
            const currentTime = Math.round(new Date().getTime() / 1000);
            if (expirationTime && expirationTime_ext && expirationTime - currentTime <= 0 && expirationTime_ext - currentTime > 0) {
                Swal.fire({
                    //title: "¿Desea mas tiempo?",
                    html: "Se cerrara su sesión en breve",
                    timer: 10000,
                    timerProgressBar: true,
                    showCancelButton: true,
                    confirmButtonText: "Mantener sesión",
                    cancelButtonText: "Cerrar ahora",
                }).then(result => {
                    if (result.isConfirmed) {
                        this._authMicrosoft.refreshTokenAPI().subscribe(res => {
                            this._authMicrosoft.token = res.access_token
                            this._authMicrosoft.refresh = res.refresh_token
                            this._sharingService.sharingTokenData = res.access_token
                            this._sharingService.sharingRefreshTokenData = res.refresh_token
                            this._localService.setJsonValue("access_token", res.access_token)
                            this._localService.setJsonValue('expires_in', (currentTime + res.ext_expires_in - 900) )
                            this._localService.setJsonValue('ext_expires_in', (currentTime + res.ext_expires_in) )
                            this.getUserData()
                        })
                    } else {
                        this._authMicrosoft.logout().subscribe(res => {
                            this._authMicrosoft.clearSesion()
                            let a = document.createElement('a')
                            a.href = res.url
                            a.click()
                        })
                    }
                })

            }
            if(expirationTime_ext - currentTime <= 0){
                this._authMicrosoft.clearSesion()
                this._authMicrosoft.logout().subscribe(res => {
                    let a = document.createElement('a')
                    a.href = res.url
                    a.click()
                })
            }
        } else {
           
        }
        return next.handle(request).pipe(
            catchError(error => {
                return throwError(error);
            })
        );
    }

    initializeUserData(user) {
        this._sharingService.sharingEntitiesData = user.entities
        this._sharingService.sharingIdentityData = user.identity
        this._sharingService.sharingEntityData = user.entity
        this._sharingService.sharingRoleData = user.role
        this._sharingService.sharingMenuData = user.menu
        this._sharingService.sharingTokenData = this._localService.getJsonValue('access_token')
    }

    getUserData() {
        Swal.fire({
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
        this._authMicrosoft.decryptToken().subscribe(res => {
            this._userService.processUserData(res.user.user)
            this._userService.processUserPhoto(res.photo.body.data)
            this.initializeUserData(res.user)
            Swal.close()
        })
    }
}