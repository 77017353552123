export class Solicitud_Usuario{
    constructor(
        public nombre : String,
        public apellido : String,
        public rut : String,
        public email : String,
        public entidad : Array<String>,
        public cargo : String,
        public privilegios : String,
        public campo_institucion : String
    ){}
};