import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Entidad } from '../models/usuario/entidad';
import { Global } from './global';
import { UserService } from './user.service';
import { LocalService } from './localService';
import { SharingService } from './sharing.service';

@Injectable()
export class EntidadService {
  public entidades: Array<Entidad>;
  public url: string;
  public identity;
  public token;
  public entidad;
  private entidadSelSubject: BehaviorSubject<Entidad>; // BehaviorSubject declaration

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private _localService: LocalService,
    private _sharingService: SharingService
  ) {
    this.url = Global.url;
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.entidadSelSubject = new BehaviorSubject<Entidad>(null); // Initialize BehaviorSubject
  }

  getEntidades(entidad): Observable<any> {
    let params = JSON.stringify(entidad);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
    return this._http.post(this.url + 'get-entidades', params, { headers: headers });
  }

  getEntidadesAll(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
    return this._http.get(this.url + 'get-entidades', { headers: headers });
  }

  getEntidad() {
    let entidad = JSON.parse(this._localService.getJsonValue('entidad'));
    if (entidad && entidad != null && entidad != undefined && entidad != "undefined") {
      this.entidad = entidad;
    } else {
      this.entidad = null;
    }
    return this.entidad;
  }

  cambiarEntidadSel(entidad): Observable<any> {
    let params = JSON.stringify(entidad);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
    return this._http.post(this.url + 'cambiar_entidad_sel', params, { headers: headers })
  }

  getEntidadSel(): void {
    let entity = this._userService.getIdentity()
    this.entidadSelSubject.next(entity.entidad.seleccionada)
  }

  // Public method to get the BehaviorSubject as an Observable
  getEntidadSelSubject(): Observable<Entidad> {
    return this.entidadSelSubject.asObservable();
  }
}
