export class Evento {
	constructor(
		public _id: string,
		public campo: JSON,
		public nombre: string,
		public fecha_inicio: Date,
		public fecha_termino: Date,
		public comentario: string,
		public activo: number

	) {}
}
