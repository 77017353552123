<!-- begin #header -->
<div *ngIf="mostrar_header == true" id="header" class="header navbar-default"
  [class.navbar-inverse]="env != 'Production'">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <button type="button" class="navbar-toggle pull-left" *ngIf="this.pageSidebarTwo"
      (click)="mobileRightSidebarToggle()">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a href="javascript:;" class="navbar-brand"><img [src]="logo" width="25px">
      &nbsp; Personas<b class="text-info">UCh</b></a>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileTopMenuToggle()"
      *ngIf="this.pageSettings.pageTopMenu && !this.pageSettings.pageWithoutSidebar">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <span *ngIf="env != 'Production'" [ngClass]="getClassLevelEnv()" style="font-size: 
								   1.6em; 
								   border-radius: 
								   5px; text-transform: uppercase;">{{env}}</span>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileMegaMenuToggle()"
      *ngIf="this.pageSettings.pageMegaMenu">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()"
      *ngIf="!this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()"
      *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  
  <ul class="navbar-nav navbar-right">

    <li class="dropdown" ngbDropdown *ngIf="entidades">
      <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
        <i class="fa fa-university"></i>
        <span class="label label-default">{{entidades.length}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-right overflow-auto width-400" style="max-height: 500px;"
        ngbDropdownMenu>
        <div class="serchEntitys">
          <div class="dropdown-header">Entidades ({{entidades.length}})</div>
          <div class="media bg-white">
            <div class="m-10 width-full">
              <input class="form-control" type="text" placeholder="Busca tu entidad aquí..."
                (keyup)="filtrarEntidades($event)" />
            </div>
          </div>
        </div>
        <div *ngIf="entidades.length > 0">
          <a *ngFor="let entidad of entidades" href="javascript:;" (click)="cambiaentidad(entidad)"
            class="dropdown-item media">
            <div class="media-left">
              <i class="text-info far fa-3x fa-fw m-r-10 fa-check-circle"></i>
            </div>
            <div class="media-body">
              <h6 class="media-heading">{{entidad.codigo}} {{entidad.grupo}} <label
                  class="label label-light">{{entidad.campo}}</label></h6>
              <div class="text-muted f-s-11">{{entidad.nombre}}</div>
            </div>
          </a>
        </div>
        <div *ngIf="entidades.length === 0">
          <a href="javascript:;" class="dropdown-item media">
            <div class="media-left">
              <i class="text-danger far fa-3x fa-fw m-r-10 fa-times-circle"></i>
            </div>
            <div class="media-body ">
              <h6 class="media-heading text-wrap">La entidad que esta buscando no se encontró o no tiene acceso a ella.
              </h6>
            </div>
          </a>
        </div>
      </div>
    </li>

    <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <img *ngIf="profilePhotoURL" [src]="profilePhotoURL" alt="profilePhoto" (error)="profilePhotoURL = null"/>
        <span>
          <span class="d-none d-md-inline">{{userData?.displayName}}</span>
          <b class="caret"></b>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a href="https://myworkaccount.microsoft.com/" class="dropdown-item">Mi Cuenta</a>
        <a href="javascript:;" (click)="logout()" class="dropdown-item">Cerrar Sesión</a>
      </div>
    </li>
    <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
    <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
      <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>

</div>
