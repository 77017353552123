<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
    <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
    <li class="breadcrumb-item">{{descripcion}}</li>
    <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-edit"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<div class="row" *ngIf="generica != 1">
    <div class="col-md-11">
        <div class="panel panel-iatuch">
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">

                </div>
                <h4 class="panel-title"><i class="fas fa-info-circle fa-fw"></i> Actualizar Informacion Personal</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body ">
                <div class="alert fade show m-b-10" [class.alert-warning]="generica == 2" *ngIf="generica == 2">
                    <i class="fa" [class.fa-info-circle]="generica == 2"></i>
                    {{messages}}
                </div>
                <form #updateUser="ngForm" (ngSubmit)="onSubmit2(updateUser.value)">

                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label"> Nombres: </label>
                        <div class="col-sm-4">
                            <input #nombre="ngModel" name="nombre" [(ngModel)]="update.nombre" type="text"
                                class="form-control" placeholder="Nombres" required />
                            <div class="invalid-feedback d-block" *ngIf="">Campo Requerido</div>
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label">Apellidos: </label>
                        <div class="col-sm-4">
                            <input #apellido="ngModel" name="apellido" [(ngModel)]="update.apellido" type="text"
                                class="form-control" placeholder="Apellidos" required />
                            <div class="invalid-feedback d-block" *ngIf="">Campo Requerido</div>
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label">Rut: </label>
                        <div class="col-sm-4">
                            <input #rut="ngModel" name="rut" [(ngModel)]="update.rut" type="text" class="form-control"
                                placeholder="Ingrese Rut" [readonly]="update.rutX == true" required />
                            <div class="invalid-feedback d-block" *ngIf="">Campo Requerido</div>
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label">Correo: </label>
                        <div class="col-sm-4">
                            <input #correo="ngModel" name="correo" [(ngModel)]="update.mail" type="text"
                                class="form-control" placeholder="Correo Institucional" required />
                            <div class="invalid-feedback d-block" *ngIf="">Campo Requerido</div>
                        </div>
                    </div>

                    <div class="form-group row m-b-0">
                        <label class="col-sm-3 col-form-label">&nbsp;&nbsp;&nbsp;</label>
                        <div class="col-md-8 col-sm-8">
                            <button type="submit" [disabled]="updateUser.invalid" class="btn btn-primary"><i
                                    class="fas fa-user-edit"></i> Actualizar Datos</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="generica != 2 ">
    <div class="col-md-11">
        <div class="panel panel-iatuch">
            <!-- begin panel-heading -->
            <div class="panel-heading ui-sortable-handle">
                <div class="panel-heading-btn">

                </div>
                <h4 class="panel-title"><i class="fas fa-key fa-fw"></i> Cambiar Contraseña</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body ">
                <div class="alert fade show m-b-10" [class.alert-warning]="generica == 1" *ngIf="generica == 1">
                    <i class="fa" [class.fa-info-circle]="generica == 1"></i>
                    {{messages}}
                </div>

                <form #modcontrasenaForm="ngForm" (ngSubmit)="onSubmit(modcontrasenaForm)" class=".form-control">
                    <div class="col-sm-12" [class.hide]="estado == undefined">
                        <div class="alert fade show m-b-10" [class.alert-danger]="estado == false"
                            [class.alert-success]="estado == true">
                            <i class="fa" [class.fa-exclamation-triangle]="estado == false"
                                [class.fa-check-circle]="estado == true"></i>
                            {{message}}
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label"> Clave Actual</label>
                        <div class="col-sm-4">
                            <input [class.is-invalid]="!claveactual.valid && claveactual.touched" #claveactual="ngModel"
                                name="claveactual" [(ngModel)]="pass.claveactual" type="password" class="form-control"
                                placeholder="Contraseña" required />
                            <div class="invalid-feedback d-block" *ngIf="!claveactual.valid && claveactual.touched">
                                Campo Requerido</div>
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label">Nueva Contraseña</label>
                        <div class="col-sm-4">
                            <input [class.is-invalid]="!clavenueva.valid && clavenueva.touched" #clavenueva="ngModel"
                                name="clavenueva" [(ngModel)]="pass.clavenueva" type="password" class="form-control"
                                placeholder="Nueva Contraseña" required />
                            <div class="invalid-feedback d-block" *ngIf="!clavenueva.valid && clavenueva.touched">
                                Campo Requerido</div>
                        </div>
                    </div>
                    <div class="form-group row m-b-10">
                        <label class="col-sm-3 col-form-label">Confirmar Nueva Contraseña</label>
                        <div class="col-sm-4">
                            <input [class.is-invalid]="!claveconfirmacion.valid && claveconfirmacion.touched"
                                #claveconfirmacion="ngModel" name="claveconfirmacion"
                                [(ngModel)]="pass.claveconfirmacion" type="password" class="form-control"
                                placeholder="Confirme Contraseña" required />
                            <div class="invalid-feedback d-block"
                                *ngIf="!claveconfirmacion.valid && claveconfirmacion.touched">Campo Requerido</div>
                        </div>
                    </div>

                    <div class="form-group row m-b-0">
                        <label class="col-sm-3 col-form-label">&nbsp;&nbsp;&nbsp;</label>
                        <div class="col-md-8 col-sm-8">
                            <button type="submit" class="btn btn-primary" [disabled]="modcontrasenaForm.invalid"><i
                                    class="fas fa-key fa-fw"></i> Cambiar Contraseña</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>