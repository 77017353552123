<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
	<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li ngbNavItem>
		<a ngbNavLink (click)="nuevoPago()" [class.active]="pagos == null && 
			   registros_pago == null"><i class="fa fa-plus"></i>
			Nuevo Pago</a>
	</li>
	<li ngbNavItem>
		<a ngbNavLink (click)="traePagos()" [class.active]="pagos"><i class="fa 
			   fa-money-bill-alt"></i> Pagos</a>
	</li>
	<li ngbNavItem>
		<a ngbNavLink *ngIf="registros_pago" [class.active]="registros_pago"> <i class="fa fa-money-bill-alt"></i>
			Registros Pago </a>
	</li>
</ul>
<div class="tab-content bg-white p-15 rounded-bottom">
	<div class="tab-pane fade active show" id="default-tab-1">
		<div class="row">
			<div class="col-md-12 mb-2">
				<!--*ngIf="pagos == null && registros_pago == null"-->
				<form *ngIf="pagos == null && registros_pago == null" novalidate [formGroup]="crear_pago"
					(ngSubmit)="onSubmit()">

					<div class="form-row m-15 border rounder p-15">

						<div class="form-group col-md-3">
							<label>Cuenta</label>
							<input type="text" class="form-control" name="" placeholder="Cuenta"
								formControlName="cuenta_banco">

						</div>
						<div class="form-group 
						 col-md-3">
							<label>Banco</label>

							<select name="" class="form-control" formControlName="banco" placeholder="Seleccione...">
								<option value="" disabled selected>Seleccione...</option>
								<!--<option [ngValue]="b" *ngFor="let b of bancos">{{b.codigo}} - {{b.descripcion}}
								</option>-->
								<ng-container *ngFor="let b of bancos">
									<option *ngIf="b.importador"  [ngValue]="b" >{{b.codigo}} - {{b.descripcion}}</option>
								</ng-container>
							</select>
						</div>


						<div class="form-group col-md-3">
							<label>Descripción</label>
							<input type="text" class="form-control" name="nombre_pago" placeholder="Descripción Pago"
								formControlName="nombre_pago">

						</div>

						<div class="form-group col-md-3">
							<label>Fecha Pago</label>
							<div class="input-group">
								<input class="form-control" placeholder="yyyy-mm-dd" formControlName="fecha_pago"
									[(ngModel)]="fecha_pago" ngbDatepicker #d="ngbDatepicker" readonly>
								<div class="input-group-append">
									<button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
										type="button"><i class="fa 
							    fa-calendar"></i></button>
								</div>
							</div>
						</div>
						<div class="form-group col-md-3">
							<label>Tipo de Pago</label>
							<select name="tipo_pago" class="form-control" formControlName="tipo_pago"
								placeholder="Seleccione...">
								<option value="" disabled selected>Seleccione...</option>
								<option [ngValue]="tp" *ngFor="let tp of tipos_pago">{{tp.nombre}}
								</option>
							</select>
						</div>

						<div class="form-group col-md-3">
							<label>Periodo</label>
							<select name="periodo" class="form-control" formControlName="periodo"
								placeholder="Seleccione...">
								<option value="" disabled selected>Seleccione...</option>
								<option [ngValue]="i" *ngFor="let m of meses; let i=index">{{m}}
								</option>
							</select>
						</div>

						<div class="form-group col-md-3">
							<label>Tipo de Servicio</label>
							<select class="form-control" name="tipo_servicio" formControlName="tipo_servicio"
								placeholder="Seleccione...">
								<option value="" disabled selected>Seleccione...</option>
								<option [ngValue]="ts" *ngFor="let ts of tipos_servicio">{{ts.descripcion}}
								</option>
							</select>
						</div>

						<div class="form-group col-md-3">
							<label>Glosa cuenta cargo</label>
							<input type="text" class="form-control" name="glosa_cuenta_cargo"
								formControlName="glosa_cuenta_cargo" placeholder="Glosa cuenta cargo">
						</div>
						<div class="form-group col-md-3">
							<label>Glosa cuenta abono</label>
							<input type="text" class="form-control" name="glosa_cuenta_abono"
								formControlName="glosa_cuenta_abono" placeholder="Glosa cuenta abono">
						</div>

						<div class="form-group col-md-12">
							<button type="submit" class="btn btn-primary" [disabled]="crear_pago.invalid">Crear
								Pago</button>
						</div>
						<!--[disabled]="crear_pago.invalid" -->
					</div>
				</form>
			</div>

			<div class="row col-md-12 mb-15">
				<div *ngIf="pagos" class="col-md-6 form-row ">
					<!--<input class="form-control" type="text" placeholder="Buscar por año" (keyup)="updateFilter($event)" />-->
					<div class="col-md-4">
						<label for="">Seleccione Periodo: </label>
					
						<select [(ngModel)]="filterPYear" name="filterPYear" id="" class="form-control">
							<option *ngFor="let y of getYears(); let i=index;" [value]="y"> {{y}}</option>
						</select>
					</div>
					<div class="col-md-2">
						<label for="">&nbsp; &nbsp;&nbsp;</label>
					<button class="btn btn-primary form-control" (click)="traePagos(filterPYear)">Buscar</button>
					</div>
	
				</div>
			</div>
			
			<br><br><br><br>
			<div *ngIf="pagos" class="col-md-12 table-responsive text-nowrap table-data mt-15">
				<table class="table table-hover">
					<thead>
						<tr>
							<th>#</th>
							<th>Cuenta</th>
							<th>Nombre</th>
							<th>Banco</th>
							<th>Tipo</th>
							<th>Servicio</th>
							<th>Fecha</th>
							<th><i class="fa fa-cogs"></i></th>
						</tr>
						
					</thead>
					<tbody>
						<tr *ngFor="let p of pagos; let i=index;">
							<td>{{ i + 1 }}</td>
							<td> {{p.cuenta_banco}}</td>
							<td>{{p.nombre_pago}}</td>
							<td>{{p.banco.descripcion}}</td>
							<td>{{p.tipo_pago.nombre}}</td>
							<td>{{p.tipo_servicio.descripcion}}</td>
							<td>{{ p.fecha_pago | date:'dd/MM/yyyy HH:mm'}}</td>
							<td><button (click)="btnTraeRegistrosPago(p._id)" class="btn btn-info
				   btn-xs"><i class="fa fa-eye"></i></button>
								<!--&nbsp;
								<button class="btn btn-xs btn-primary"> <i class="fa 
			       fa-pencil-alt"></i></button>-->
							</td>

						</tr>
					</tbody>
					<tfoot>
						<tr *ngIf="pagos[0]">
							<th colspan="7">{{pagos.length}} en total</th>
						</tr>
					</tfoot>
				</table>
			</div>
			<form *ngIf="registros_pago && mostrar_modificar_pago == true" #modificarPagoForm="ngForm"
				(ngSubmit)="onSubmitModificarPago(modificarPagoForm)">

				<div class="form-row m-15 border rounder p-15">

					<div class="form-group col-md-3">
						<label>Cuenta</label>
						<input type="text" class="form-control 
									     form-control-sm" name="cuenta_banco" cuenta_banco="ngModel" [(ngModel)]="pago.cuenta_banco"
							placeholder="Cuenta" required>

					</div>
					<div class="form-group 

col-md-3">
						<label>Banco</label>
						<select name="banco" class="form-control 
									      form-control-sm" #banco="ngModel" [(ngModel)]="pago.banco._id" required>
							<option disabled value="null">Seleccione Banco...</option>
							<option *ngFor="let banco 
											  of bancos 
											  " [selected]="banco._id==pago.banco._id" [value]="banco._id">
								{{banco.descripcion}}
							<option>
						</select>
					</div>


					<div class="form-group col-md-3">
						<label>Descripción</label>
						<input type="text" class="form-control 
									     form-control-sm" name="nombre_pago" #nombre_pago="ngModel" [(ngModel)]="pago.nombre_pago" placeholder="Descripción 
										   Pago" required>

					</div>

					<div class="form-group 
									 col-md-3">
						<label>Fecha Pago</label>
						<div class="input-group">
							<input #inputSearch (closed)="updateFilterDate(inputSearch.value)"
								[value]="fecha_modificacion" class="form-control 
									   form-control-sm" placeholder="dd-mm-yyyy" name="fecha_pago" [(ngModel)]="fecha_modificacion_model"
								ngbDatepicker #d="ngbDatepicker" readonly>

							<div class="input-group-append">
								<button class="btn 
									     btn-sm 
									     btn-outline-secondary 
									     calendar" (click)="d.toggle()" type="button"><i class="fa 
									     fa-calendar"></i></button>
							</div>
						</div>
					</div>
					<div class="form-group col-md-3">
						<label>Tipo de Pago</label>
						<select name="tipo_pago" class="form-control 
									      form-control-sm" #tipo_pago="ngModel" [(ngModel)]="pago.tipo_pago._id" required>
							<option disabled value="null">Seleccione Tipo de Pago...</option>
							<option *ngFor="let tp of 
											  tipos_pago 
											  " [selected]="tp._id
										     == 
										     pago.tipo_pago._id" [value]="tp._id">
								{{tp.nombre}}
							<option>
						</select>
					</div>

					<div class="form-group col-md-3">
						<label>Periodo</label>

						<select name="periodo" #mes_periodo="ngModel" class="form-control 
									 form-control-sm" [(ngModel)]="pago.mes_periodo" required>
							<option disabled value="null">Seleccione Periodo...</option>
							<ng-template ngFor let-m [ngForOf]="meses" let-i="index">
								<option [selected]="i 
											  ==pago.mes_periodo 
											  " [value]="i" *ngIf="i<= 
										     mes_actual 
										     && 
										     m!=null">
									{{m}}
								<option>
							</ng-template>

						</select>
					</div>

					<div class="form-group col-md-3">
						<label>Tipo de Servicio</label>
						<select name="tipo_servicio" #tipo_servicio="ngModel" class="form-control 
									      form-control-sm 
									      " [(ngModel)]="pago.tipo_servicio._id" required>
							<option [selected]="pago.tipo_servicio._id==null" value="null">Seleccione Tipo de
								Servicio...</option>
							<option *ngFor="let ts of tipos_servicio" [selected]="ts._id==pago.tipo_servicio._id"
								[value]="ts._id">
								{{ts.descripcion}}
							<option>
						</select>
					</div>

					<div class="form-group col-md-3">
						<label>Glosa cuenta cargo</label>
						<input type="text" class="form-control 
									     form-control-sm" name="glosa_cuenta_cargo" #glosa_cuenta_cargo="ngModel"
							[(ngModel)]="pago.glosa_cuenta_cargo" placeholder="Glosa 
										   cuenta 
										   cargo" required>
					</div>
					<div class="form-group col-md-3">
						<label>Glosa cuenta abono</label>
						<input type="text" class="form-control form-control-sm" name="glosa_cuenta_abono"
							#m_glosa_cuenta_abono="ngModel" [(ngModel)]="pago.glosa_cuenta_abono"
							placeholder="Glosa cuenta abono" required>
					</div>

					<div class="form-group col-md-12">
						<button type="submit" [disabled]="modificarPagoForm.invalid" class="btn btn-primary">Actualizar
							Pago</button>
					</div>


				</div>
			</form>
			<div *ngIf="registros_pago" class="col-md-12 form-row">
				<label class="mt-1 ml-3">Planilla</label> <input [disabled]="paginas_registros_pago == 1" class="form-control 
			   form-control-sm col-md-2 mr-1 ml-1" style="max-width:40px; 
								max-height:20;" [(ngModel)]="pagina_registros_pago" (keyup.enter)="btnTraeRegistrosPago(pago._id)">

				<label class="mt-1 mr-1">/ {{paginas_registros_pago}} Total
					Registros: {{ cantidad_registros_pago }}</label>
				<button (click)="generarArchivoPago(pago._id)" class="btn btn-xs 
									 btn-gray"><i class="fa fa-file-alt"></i> Generar
					TXT</button>
				&nbsp;
				<button (click)="sincronizarConAps(pago._id)" class="btn btn-xs
									 btn-gray"><i class="fa fa-sync"></i> Sincronizar con
					APS</button>
				&nbsp;
				<button *ngIf="btn_eliminar_registros_pagos" (click)="eleiminarRegistrosPagosSeleccionados()" class="btn btn-xs
					btn-danger"><i class="fa fa-trash-alt"></i> Eliminar </button>
			</div>

			<!--<div *ngIf="registros_pago" class="col-md-6 m-15">
				<input class="form-control" type="text" placeholder="Buscar por Nombre, Email, Banco, Cuenta..."
					(keyup)="updateFilter($event)" />
			</div>-->
			<ng-container outsideBody>
				<hr class="m-0" />
				<div *ngIf="registros_pago" class="col-md-12 table-responsive text-nowrap 
		    table-data">
					<table class="table table-sm table-hover">
						<thead class="text-left">
							<tr>
								<th><input (change)="eventCheckAll($event)" type="checkbox"
										[value]="check_todos_registros_pagos" [checked]="check_todos_registros_pagos">
								</th>
								<th>#</th>
								<th>Nombre</th>
								<th>Email</th>
								<th>Banco</th>
								<th>Cuenta</th>
								<th>Monto</th>
								<th><i class="fa fa-cogs"></i></th>
							</tr>
							<tr>
								<th></th>
								<th></th>
								<th><input type="text" class="form-control form-control-sm" [(ngModel)]="registroPagoParams.nombre_beneficiario" placeholder="Ingrese Nombre..." (keyup.enter)="btnTraeRegistrosPago(pago._id)"></th>
								<th><input type="text" class="form-control form-control-sm" [(ngModel)]="registroPagoParams.email_beneficiario" placeholder="Ingrese email..."(keyup.enter)="btnTraeRegistrosPago(pago._id)"></th>
								<th><input type="text" class="form-control form-control-sm" [(ngModel)]="registroPagoParams.nombre_banco" placeholder="Ingrese Banco..."(keyup.enter)="btnTraeRegistrosPago(pago._id)"></th>
								<th><input type="text" class="form-control form-control-sm" [(ngModel)]="registroPagoParams.numero_cuenta" placeholder="Ingrese Cuenta..."(keyup.enter)="btnTraeRegistrosPago(pago._id)"></th>
								<th><input type="text" class="form-control form-control-sm" [(ngModel)]="registroPagoParams.monto_pago" placeholder="Ingrese Monto..."(keyup.enter)="btnTraeRegistrosPago(pago._id)"></th>
								<th><button class="btn btn-sm btn-primary" type="button" (click)="btnTraeRegistrosPago(pago._id)"><i class="fa fa-search"></i></button> </th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of registros_pago; let i=index;">
								<td><input type="checkbox" (change)="eventCheck($event, i)" name="" id=""
										[value]="r.check" [checked]="r.check"></td>
								<td>{{ i + 1 }}</td>
								<td>{{r.nombre_beneficiario}}</td>
								<td>{{r.email_beneficiario}}</td>
								<td>{{r.nombre_banco}}</td>
								<td>{{r.numero_cuenta}}</td>
								<td> <label class=" text-black-darker">${{r.monto_pago |
										currency:'CLP'}}</label></td>
								<td>
									<button class="btn btn-xs btn-danger" (click)="eliminarRegistroPago(r._id)"> <i
											class="fa fa-trash-alt"></i></button> &nbsp;
									<button class="btn btn-xs btn-primary"
										(click)="open(modalregistros);seleccionarRegistroPago(i)">
										<i class="fa fa-pencil-alt"></i></button>


								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr *ngIf="registros_pago[0]">
								<th colspan="7">{{registros_pago.length}} en total</th>
							</tr>
						</tfoot>
					</table>
				</div>
			</ng-container>


		</div>
	</div>
</div>

<div [ngbNavOutlet]="nav"></div>
<!--<panel >


  </panel>-->
<ng-template #modalregistros let-c="close" let-d="dismiss">

	<form #ActualizarRegistroPagoForm="ngForm" (ngSubmit)="actualizarRegistroPagoSubmit(ActualizarRegistroPagoForm)">
		<div class="modal-header">
			<h4 class="modal-title "><i class="fa fa-money-bill-alt"></i> Registro</h4>
			<button type="button" class="close btn btn-white" (click)="d('Cross 
		      click')">×</button>
		</div>
		<div class="modal-body">
			<div class="form-row">
				<div class="form-group col-md-4">
					<label>Rut:</label>
					<input type="text" class="form-control form-control-sm" name="rut_beneficiario"
						#rut_beneficiario="ngModel" [(ngModel)]="registro_pago.rut_beneficiario"
						placeholder="Rut Beneficiario" required>
					<div class="invalid-feedback d-block" *ngIf="!rut_beneficiario.valid 
			&& rut_beneficiario.touched">Debe Ingresar un Rut Valido!
					</div>
				</div>
				<div class="form-group col-md-8">
					<label>Nombre:</label>
					<input type="text" class="form-control form-control-sm" name="nombre_beneficiario"
						#nombre_beneficiario="ngModel" [(ngModel)]="registro_pago.nombre_beneficiario"
						placeholder="Nombre Beneficiario" required>
					<div class="invalid-feedback d-block" *ngIf="!nombre_beneficiario.valid && 
			nombre_beneficiario.touched">Debe Ingresar un
						Nombre Valido!
					</div>
				</div>
				<div class="form-group col-md-6">
					<label>Email:</label>
					<input type="text" class="form-control form-control-sm" name="email_beneficiario"
						#email_beneficiario="ngModel" [(ngModel)]="registro_pago.email_beneficiario"
						placeholder="Email Beneficiario" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
					<div class="invalid-feedback d-block" *ngIf="!email_beneficiario.valid && 
			email_beneficiario.touched">Debe Ingresar un Email
						Valido!
					</div>
				</div>
				<div class="form-group col-md-6">
					<label>Banco:</label>
					<select class="form-control form-control-sm" name="banco" #banco="ngModel"
						[(ngModel)]="registro_pago.codigo_banco" (change)="cambiarBancoRegistro($event.target.value)"
						required>
						<option value=""></option>
						<option *ngFor="let b of bancos" [selected]="b.codigo==registro_pago.codigo_banco"
							[value]="b.codigo">
							{{b.codigo}} - {{ b.descripcion}}
						<option>
					</select>
					<div class="invalid-feedback d-block" *ngIf="!banco.valid && 
			  banco.touched">Debe Ingresar un tipo de Cuenta!
					</div>

				</div>

				<div class="form-group col-md-6">
					<label>Tipo Cuenta:</label>

					<select class="form-control form-control-sm" [(ngModel)]="registro_pago.tipo_cuenta._id"
						name="tipo_cuenta" #tipo_cuenta="ngModel"
						(change)="cambiarTipoCuentaRegistro($event.target.value)" required>
						<option value=""></option>
						<option *ngFor="let tc of tipos_cuenta" [selected]="tc.codigo==registro_pago.tipo_cuenta.codigo"
							[value]="tc._id">
							{{ tc.descripcion}}
						<option>
					</select>
					<div class="invalid-feedback d-block" *ngIf="!tipo_cuenta.valid && 
			  tipo_cuenta.touched">Debe Ingresar un tipo de Cuenta!
					</div>

				</div>
				<div class="form-group col-md-6">
					<label>Nro Cuenta:</label>
					<input type="text" class="form-control form-control-sm" name="numero_cuenta"
						#numero_cuenta="ngModel" [(ngModel)]="registro_pago.numero_cuenta"
						placeholder="Numero de Cuenta" required>
					<div class="invalid-feedback d-block" *ngIf="!numero_cuenta.valid && 
			numero_cuenta.touched">Ingrese una cuenta valida!
					</div>
				</div>
				<div class="form-group col-md-12">
					<label>Referencia Cliente:</label>
					<input type="text" class="form-control form-control-sm" name="referencia_cliente"
						#referencia_cliente="ngModel" [(ngModel)]="registro_pago.referencia_cliente"
						placeholder="Referencia Cliente" required>
					<div class="invalid-feedback d-block" *ngIf="!referencia_cliente.valid && 
			referencia_cliente.touched">Ingrese una descripción valida!
					</div>
				</div>

				<div class="form-group col-md-6">
					<label>Monto:</label>
					<input type="text" class="form-control form-control-sm" name="monto_pago" #monto_pago="ngModel"
						[(ngModel)]="registro_pago.monto_pago" placeholder="Monto" pattern="^[1-9]\d*$" required>
					<div class="invalid-feedback d-block" *ngIf="!monto_pago.valid && 
			monto_pago.touched">Ingrese un Monto Valido
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="submit" [disabled]="ActualizarRegistroPagoForm.invalid" class="btn btn-success"><i
					class="fa fa-sync"></i>
				Actualizar</button>
			<button type="button" class="btn btn-danger" (click)="d('Cross 
		      click')">Cerrar</button>
		</div>


	</form>
</ng-template>