import {Component, OnInit} from '@angular/core';

import * as global from '../../../config/globals';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {CGASService} from '../../../services/cgas.service'
import {UserService} from '../../../services/user.service'
import {ReporteEvento} from '../../../models/cgas/reporte_evento'
import {Evento} from '../../../models/cgas/evento'
import {Delegado} from '../../../models/cgas/delegado'
import pageSettings from '../../../config/page-settings';
import {from} from 'rxjs';

@Component({
	selector: 'app-reporte-asistencia',
	templateUrl: './reporte-asistencia.component.html',
	styleUrls: ['./reporte-asistencia.component.css'],
	providers: [CGASService, UserService]

})
export class ReporteAsistenciaComponent implements OnInit {

	pageSettings = pageSettings;
	global = global
	pieChartData
	chartColor

	public titulo = "Reporte Asistencia";
	public descripcion = "Eventos";
	public icono = "fas fa-tachometer-alt fa-fw";
	public eventos: Array<Evento>
	public reporte_evento: ReporteEvento
	public evento: Evento
	public delegado: Delegado
	public titulo_panel: String = this.titulo
	public seleccionado = ""
	public user_img = document.location.origin +
		"/assets/img/user/logoiasd.png";
	public delegado_img: String
	public ruta_img: String = document.location.origin +
		'/assets/delegado/'

	constructor(
		private _userService: UserService,
		private _cgasService: CGASService,

	) {}

	ngOnInit() {
		this.traeEventos()
		this.chartColor = {
			domain: [global.COLOR_BLUE, global.COLOR_GREEN,
			global.COLOR_PURPLE, global.COLOR_BLACK]
		};

	}

	traeReporte(evento, campo) {
		this._cgasService.reporteEvento(campo, evento).subscribe(
			response => {
				console.log(response)
				if (response.presentes > 0) {

					let ausentes = response.total
						- response.presentes
					let porc_presentes =
						(response.presentes * 100) /
						response.total
					porc_presentes =
						Math.round(porc_presentes)
					let porc_ausentes = (ausentes * 100) /
						response.total
					porc_ausentes =
						Math.round(porc_ausentes)
					this.reporte_evento = new
						ReporteEvento(response.total,
							response.presentes,
							ausentes,
							Number(
								porc_presentes),
							Number(porc_ausentes),
							response.ultimo)
					this.pieChartData =
						[{
							"name": "Presentes", "value":
								this.reporte_evento.presentes
						},
						{
							"name": "Ausentes", "value":
								this.reporte_evento.total - this.reporte_evento.presentes
						}];
					this.delegado =
						response.ultimo.delegado
				} else {
					this.reporte_evento = undefined
					this.pieChartData = undefined
					this.delegado = undefined
				}

				//console.log(this.reporte_evento)
				//console.log(this.pieChartData)
			})

	}
	traeEventos() {
		//console.log("trae Eventos")
		//returnlet campo = "MCCh"
		let campo = "MCCh"
		this._cgasService.traeEventos().subscribe(
			response => {
				this.eventos = response.eventos

				//console.log(this.eventos)
			}
		)
	}
	seleccionarEvento(evento) {
		//console.log(evento)
		this.evento = new Evento(evento._id,
			evento.campo, evento.nombre,
			evento.fecha_inicio,
			evento.fecha_termino,
			evento.comentario, evento.activo)
		this.titulo_panel = this.titulo + " - " +
			this.evento.nombre
		this.traeReporte(this.evento._id, evento.campo._id)
		//console.log(this.evento)
	}

}
