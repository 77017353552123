import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgSelectConfig, NgSelectComponent } from '@ng-select/ng-select'
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { NgModel, FormGroup, FormControl, FormArray } from '@angular/forms';
import { formatDate } from '@angular/common'
import { ApsService } from '../../../services/aps.service'
import { EntidadService } from '../../../services/entidad.service';
import Swal from 'sweetalert2'
import { Global } from '../../../services/global';
import { UserService } from '../../../services/user.service';

//import { NgSelectConfig } from '@ng-select/ng-select'
import { User } from '../../../models/usuario/user';
import { Banco } from '../../../models/aps/banco';
import { EntidadAps } from '../../../models/aps/entidad_aps';
import { RegistroPago } from '../../../models/aps/registro_pago';
import { Pago } from '../../../models/aps/pago';
import { TipoCuenta } from '../../../models/aps/tipo_cuenta';
import { TipoMetodoPago } from '../../../models/aps/tipo_metodo_pago';
import { TipoPago } from '../../../models/aps/tipo_pago';
import { TipoServicio } from '../../../models/aps/tipo_servicio';
import { CrearPago } from '../../../models/aps/crear_pago';
import { NgbActiveModal, NgbModal, NgbDateStruct } from
  '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';
import { TraePagosParamsModel } from '../../../models/aps/traePagosParams.model';
import { RegistroPagoParamsModel } from '../../../models/aps/registro_pago_params.model';
import { RegistroPagoResponseModel } from '../../../models/aps/registro_pago_response.model';

import { Subscription } from 'rxjs';
import { SharingService } from '../../../services/sharing.service';
@Component({
  selector: 'app-generar-pago',
  templateUrl: './generar-pago.component.html',
  styleUrls: ['./generar-pago.component.css'],
  providers: [EntidadService, UserService, ApsService]
})
export class GenerarPagoComponent implements OnInit, OnDestroy {
  temp = []
  public banco_xd
  public icono = "fa fa-users"
  public titulo = "Generar Pagos"
  public descripcion = "APS"
  public bancos: Array<Banco>
  public entidad_aps: EntidadAps
  public registros_pago: Array<RegistroPago>
  public registro_pago: RegistroPago
  public pago: Pago
  public pagos: Array<Pago>
  public tipos_cuenta: Array<TipoCuenta>
  public tipos_metodos_pago: Array<TipoMetodoPago>
  public tipos_pago: Array<TipoPago>
  public tipos_servicio: Array<TipoServicio>
  public entidadSelSubscription: Subscription;
  //model: NgbDateStruct
  /*public crear_pago = new 
  CrearPago(null,"",null,null,null,null,'CAT_CSH_CONTRACT_ACCOUNT',null,null,null)*/
  crear_pago: FormGroup;
  public meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
    "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ]
  public fecha_actual = new Date
  public mes_actual = this.fecha_actual.getMonth()
  fecha_pago: NgbDateStruct
  fecha_pago_act: NgbDateStruct
  fecha_modificacion_model: NgbDateStruct;
  public fecha_modificacion
  public entidad_sel = this._entidadService.getEntidad();
  public cantidad_registros_pago: Number = 1
  public paginas_registros_pago: Number = 1
  public pagina_registros_pago: number = 1
  public mostrar_modificar_pago: Boolean = false
  public btn_eliminar_registros_pagos: Boolean = false
  public check_todos_registros_pagos: Boolean = false
  public filterPYear: number;
  public registroPagoParams: RegistroPagoParamsModel = new RegistroPagoParamsModel()
  constructor(
    private modalService: NgbModal,
    //public activeModal: NgbActiveModal,
    private _entidadService: EntidadService,
    private _userService: UserService,
    private _apsService: ApsService,
    private _sharingService: SharingService
  ) {

  }

  ngOnDestroy() {
    this.entidadSelSubscription.unsubscribe();
  }
  crearPagoForm() {
    this.crear_pago = new FormGroup({
      cuenta_banco: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
      ]),
      banco: new FormControl("", Validators.required),
      nombre_pago: new FormControl("", [
        Validators.required
      ]),
      fecha_pago: new FormControl("", [
        Validators.required
      ]),
      tipo_pago: new FormControl("", [
        Validators.required
      ]),
      periodo: new FormControl("", [
        Validators.required
      ]),
      medio_respaldo: new FormControl("CAT_CSH_CONTRACT_ACCOUNT", Validators.required),
      tipo_servicio: new FormControl("", [
        Validators.required
      ]),
      glosa_cuenta_cargo: new FormControl("", [
        Validators.required
      ]),
      glosa_cuenta_abono: new FormControl("", [
        Validators.required
      ])

    });
  }

  /*
  ngDoCheck() {
    var cambioEntidad = this._entidadService.getEntidad();

    if (this.entidad_sel.codigo != cambioEntidad.codigo) {
      //console.log("Son distintos! " + this.entidad_sel.codigo + "|" +
      //cambioEntidad.codigo)
      this.entidad_sel = cambioEntidad
      //console.log(this.entidad_sel)
      this.traePagos()
    }


  }*/
  ngOnInit() {

    this.entidadSelSubscription = this._sharingService.sharingEntity.subscribe(
      (entidad) => {
        this.entidad_sel = entidad;
        this.traePagos()
      }
    );

    this.crearPagoForm()
    //console.log('años: ',this.getYears()[0]);
    //console.log(this.pagos)
    var today = new Date();
    this.filterPYear = today.getFullYear();

    this.fecha_modificacion_model = {
      "year": this.fecha_actual.getFullYear(), "month": this.mes_actual + 1, "day":
        this.fecha_actual.getDate()
    }

    this.fecha_modificacion = String(this.fecha_modificacion_model.day).padStart(2,
      '0') + "-" + String(this.fecha_modificacion_model.month).padStart(2, '0') +
      "-" + this.fecha_modificacion_model.year

    let fecha_pago = this.fecha_actual.getFullYear() + '-' +
      String(this.mes_actual + 1).padStart(2, "0") + '-' +
      this.fecha_actual.getDate()
    this.setFechaPago()
    //this.crear_pago.periodo = String(this.mes_actual)
    this.traePagos(this.getYears()[0])
    this.traeBancos()
    this.traeTiposPago()
    this.traeTiposServicio()
    this.traeTiposCuenta()
  }

  setFechaPago() {
    this.fecha_pago = {
      "year": this.fecha_actual.getFullYear(), "month":
        this.mes_actual + 1, "day": this.fecha_actual.getDate()
    }
  }

  open(modal) {
    this.modalService.open(modal, {
      size: 'md'
    });
  }
  nuevoPago() {
    this.pagos = null
    this.registros_pago = null
    this.titulo = "Nuevo Pago"
  }

  traePagos(year: number = null) {
    let params = { year: year, day: 2 }
    console.log('Year: ' + year)
    this._apsService.traePagos(params).subscribe(
      response => {
        this.titulo = "Ver Pagos"
        this.pagos = response.pagos
        this.registros_pago = null
        //console.log(this.pagos)
      },
      error => {
        console.log(error)
      });
  }

  btnTraeRegistrosPago(id: string) {

    this.registroPagoParams.pago = id
    this.registroPagoParams.page_number = this.pagina_registros_pago
    console.log(this.registroPagoParams)
    this.traeRegistrosPago(this.registroPagoParams)
  }
  traeRegistrosPago(params: RegistroPagoParamsModel) {
    Swal.fire({
      text: "Cargando Registros...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    this._apsService.traeRegistrosPago(params).subscribe(
      (response: RegistroPagoResponseModel<RegistroPago>) => {
        Swal.close()
        this.pagos = null
        this.titulo = "Ver Registros de Pago"
        this.registros_pago = response.resultado
        this.temp = this.registros_pago
        this.pago = response.pago
        this.pago.periodo = new
          Date(this.pago.periodo)
        this.pago.mes_periodo =
          this.pago.periodo.getMonth()
        this.pago.fecha_pago = new
          Date(this.pago.fecha_pago)

        this.fecha_modificacion_model = {
          "year":
            this.pago.fecha_pago.getFullYear(),
          "month":
            Number(this.pago.fecha_pago.getMonth()) + 1,
          "day":
            this.pago.fecha_pago.getDate()
        }
        //console.log(this.fecha_modificacion_model)
        this.updateFilterDate(this.fecha_modificacion_model)

        this.pago.fecha_pago_json = {
          day: this.pago.fecha_pago.getDate(),
          month:
            this.pago.fecha_pago.getMonth() + 1,
          year:
            this.pago.fecha_pago.getFullYear()

        }
        this.pagina_registros_pago = response.pagina
        this.cantidad_registros_pago = response.registros
        this.paginas_registros_pago = response.paginas
        //console.log(this.pago)
        //console.log(this.registros_pago)


      },
      error => {
        console.log(error)
      }
    )
  }
  eliminarRegistroPago(id) {
    Swal.fire({
      title: 'Advertencia!',
      text: "¿Está Seguro/a de Eliminar este Registro?",
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-lg btn-warning ml-2',
        cancelButton: 'btn btn-lg btn-danger ',

      },
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._apsService.eliminarRegistroPago(id).subscribe(
          response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'EL Registro fue eliminado!',
              showConfirmButton: false,
              timer: 1500
            })
            this.registroPagoParams.pago = this.pago._id
            this.registroPagoParams.page_number = this.pagina_registros_pago
            this.traeRegistrosPago(this.registroPagoParams)
          },
          error => { console.log(error) }
        )
      }
    })

  }
  generarArchivoPago(id) {
    this._apsService.generarArchivoPago(id,
      this.pagina_registros_pago).subscribe(
        response => {
          var textFile = null
          var data = new Blob([response.contenido], { type: 'text/plain' });


          if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
          }
          var a = document.createElement("a");
          a.href = URL.createObjectURL(data);
          a.download = response.nombre_archivo, a.href;
          // start download
          a.click();
        },
        error => { console.log(error) }
      )

  }
  traeBancos() {
    this._apsService.traeBancos().subscribe(
      response => {
        //console.log(response)
        /*let vacio = {_id:null, codigo:"", descripcion: "Seleccione un 
      Banco...", activo:true}
  */
        this.bancos = response.datos
        //this.bancos = this.bancos.filter(x => x.importador == importador)
        //console.log(this.crear_pago.banco)
        /*this.bancos.push(vacio)*/
      },
      error => { console.log(error) }
    )

  }
  traeTiposPago() {
    this._apsService.traeTiposPago().subscribe(
      response => {
        //	console.log(response)
        this.tipos_pago = response.datos

      },
      error => { console.log(error) }
    )
  }
  traeTiposServicio() {
    this._apsService.traeTiposServicio().subscribe(
      response => {
        //console.log(response)
        this.tipos_servicio = response.datos

      },
      error => { console.log(error) }
    )
  }
  onSubmit() {

    let fecha_pago = this.crear_pago.value.fecha_pago
    this.crear_pago.value.fecha_pago = String(fecha_pago.month).padStart(2,
      '0') + "-" + String(fecha_pago.day).padStart(2, '0') + "-" +
      fecha_pago.year
    let periodo = this.crear_pago.value.periodo
    this.crear_pago.value.periodo = String(Number(periodo) + 1).padStart(2,
      '0') + "-" + "01" + "-" + this.fecha_actual.getFullYear()

    this.crear_pago.value.banco = this.crear_pago.value.banco._id
    this.crear_pago.value.tipo_pago = this.crear_pago.value.tipo_pago._id
    this.crear_pago.value.tipo_servicio = this.crear_pago.value.tipo_servicio._id


    let datos = this.crear_pago.value
    //      console.log(datos)
    this._apsService.crearPago(datos).subscribe(
      response => {
        //this.tipos_servicio= response.datos
        if (response.estado == true) {

          let id_pago = response.pago._id
          this.sincronizarConAps(id_pago)
          let mensaje = "El Pago ha sido Creado!"
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: mensaje,
            showConfirmButton: false,
            timer: 1500
          })
          this.crearPagoForm()
          this.setFechaPago()

        }

      },
      error => { console.log(error) }
    )

  }
  sincronizarConAps(id) {
    Swal.fire({
      text: "Sincronizando Registros con APS...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this._apsService.actualizarRegistroPagos(id).subscribe(
      response => {
        //console.log(response)
        if (response.estado == true) {
          Swal.close()
          this.registroPagoParams.pago = id
          this.registroPagoParams.page_number = this.pagina_registros_pago
          this.traeRegistrosPago(this.registroPagoParams)
        }
      },
      error => { console.log(error) }
    )
  }

  /*crearPago(datos) {

    this.crear_pago.cuenta_banco = datos.cuenta_banco
    this.crear_pago.banco = datos.banco
    this.crear_pago.nombre_pago = datos.nombre_pago
    this.crear_pago.fecha_pago = String(datos.crear_pago.month).padStart(2,
      '0') + "-" + String(datos.crear_pago.day).padStart(2, '0') + "-" +
      datos.crear_pago.year
    this.crear_pago.tipo_pago = datos.tipo_pago
    this.crear_pago.periodo = String(Number(datos.periodo) + 1).padStart(2,
      '0') + "-" + "01" + "-" + this.fecha_actual.getFullYear()
    this.crear_pago.tipo_servicio = datos.tipo_servicio
    this.crear_pago.glosa_cuenta_cargo = datos.glosa_cuenta_cargo
    this.crear_pago.glosa_cuenta_abono = datos.glosa_cuenta_abono
    this._apsService.crearPago(this.crear_pago).subscribe(
      response => {
        //this.tipos_servicio= response.datos
        if (response.estado == true) {

          let id_pago = response.pago._id
          this.sincronizarConAps(id_pago)
          let mensaje = "El Pago ha sido Creado!"
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: mensaje,
            showConfirmButton: false,
            timer: 1500
          })


        }

      },
      error => { console.log(error) }
    )
  }*/
  seleccionarRegistroPago(i) {
    this.registro_pago = this.registros_pago[i]

  }
  actualizarRegistroPagoSubmit(form) {
    Swal.fire({
      text: "Actualizando Registro...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    //console.log(form.value)
    //console.log(this.registro_pago)
    this._apsService.actualizarRegistroPago(this.registro_pago).subscribe(
      response => {
        Swal.close()
        if (response.estado == true) {
          this.modalService.dismissAll()
          Swal.fire({
            toast: true,
            icon: 'success',
            title: response.mensaje,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          })

        } else {

          Swal.fire({
            title: 'Error',
            text: response.mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-lg btn-danger ml-3',
              cancelButton: 'btn btn-lg btn-danger',

            },
            confirmButtonText: 'Ok'
          })
        }
      },
      error => { console.log(error) }
    )
  }

  traeTiposCuenta() {
    this._apsService.traeTipoCuentas().subscribe(
      response => {
        this.tipos_cuenta = response.datos
      },
      error => { console.log(error) }
    )
  }
  cambiarTipoCuentaRegistro(id) {
    let find = this.tipos_cuenta.find(f => f._id == id)
    if (find)
      this.registro_pago.tipo_cuenta = find
  }
  cambiarBancoRegistro(id) {
    let find = this.bancos.find(f => f.codigo == id)
    if (find)
      this.registro_pago.nombre_banco = find.descripcion
  }
  onSubmitModificarPago(form) {

    console.log(form.value)
  }
  updateFilterDate(date) {
    //let fecha = JSON.parse(date)
    this.fecha_modificacion =
      String(this.fecha_modificacion_model.day).padStart(2, '0') + "-" +
      String(this.fecha_modificacion_model.month).padStart(2, '0') + "-" +
      this.fecha_modificacion_model.year

  }
  updateFilter(event) {

    const val = event.target.value.toLowerCase()
    //console.log(this.temp)
    const temp = this.temp.filter(function (d) {
      //let fecha = formatDate(d.fecha, 'dd/MM/yyyy', 'es-ES')
      d.numero_cuenta = d.numero_cuenta ? d.numero_cuenta : ""
      d.email_beneficiario = d.email_beneficiario ? d.email_beneficiario : ""
      d.nombre_beneficiario = d.nombre_beneficiario ? d.nombre_beneficiario : ""
      d.nombre_banco = d.nombre_banco ? d.nombre_banco : ""

      let dataFilterer
      if ((d.numero_cuenta.toLowerCase().indexOf(val) !== -1 || !val) == false) {
        if ((d.nombre_beneficiario.toLowerCase().indexOf(val) !== -1 || !val)
          == false) {
          if ((d.email_beneficiario.toLowerCase().indexOf(val) !== -1 || !val)
            == false) {
            if ((d.nombre_banco.toLowerCase().indexOf(val) !== -1 || !val) == false) {
            } else {
              dataFilterer = d.nombre_banco.toLowerCase().indexOf(val) !== -1 || !val
            }
          } else {
            dataFilterer = d.email_beneficiario.toLowerCase().indexOf(val) !== -1 || !val
          }
        } else {
          dataFilterer = d.nombre_beneficiario.toLowerCase().indexOf(val) !== -1 || !val
        }
      } else {
        dataFilterer = d.numero_cuenta.toLowerCase().indexOf(val) !== -1 || !val
      }
      return dataFilterer;
    })
    this.registros_pago = temp;
  }
  cambiarSelect(value, objeto) {

    console.log(value)
    console.log(objeto)
  }
  eleiminarRegistrosPagosSeleccionados() {
    let eliminados = []
    this.registros_pago.map(function (e) {
      if (e.check == true) {
        eliminados.push(e._id)

      }

    })
    //console.log(eliminados)
    Swal.fire({
      title: 'Advertencia!',
      text: "¿Está Seguro/a de Eliminar los Registros Seleccionados?",
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-lg btn-warning ml-2',
        cancelButton: 'btn btn-lg btn-danger ',

      },
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._apsService.eliminarRegistrosPagosSeleccionados(this.pago._id, eliminados).subscribe(
          response => {
            //console.log(response)
            this.registroPagoParams.pago = this.pago._id
            this.registroPagoParams.page_number = this.pagina_registros_pago
            this.traeRegistrosPago(this.registroPagoParams)
            this.check_todos_registros_pagos = false
            this.btn_eliminar_registros_pagos = false
          },
          error => {
            console.log(error)
          }
        )
      }
    })
    //console.log(resultado)
  }
  eventCheck(event, index) {
    //console.log(event.target.checked, '-', index)
    this.registros_pago[index].check = event.target.checked
    let resultado = this.registros_pago.find(e => e.check == true)
    this.btn_eliminar_registros_pagos = resultado ? true : false
    this.check_todos_registros_pagos = false
    //console.log(resultado)
  }
  eventCheckAll(event) {
    this.registros_pago.map(e => e.check = event.target.checked)
    let resultado = this.registros_pago.find(e => e.check == true)
    this.btn_eliminar_registros_pagos = resultado ? true : false
    this.check_todos_registros_pagos = true
    //console.log(resultado)
  }

  getYears() {
    let arrayYears = [];
    var today = new Date();
    var thisYear = today.getFullYear();
    let year = 2021;
    while (year <= thisYear) {
      arrayYears.push(year);
      year++;
    }
    arrayYears.reverse();
    return (arrayYears);
  }

  filterF() {
    console.log(this.filterPYear);
  }
}
