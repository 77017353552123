import { Component, OnInit } from '@angular/core';
//import { getMatFormFieldDuplicatedHintError } from '@angular/material';
import { LocalService } from '../../services/localService';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

  public fecha = new Date()
  public year = this.fecha.getFullYear()

  constructor(
    private _localService: LocalService
  ){}

  ngOnInit(){
   
  }
}
