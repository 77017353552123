<ngx-loading-bar [ngClass]="{ 'top': pageSettings.pageEmpty }"></ngx-loading-bar>
<div *ngIf="isLogged">
  <!-- begin #page-container -->
  <div id="page-container" class="page-container fade page-sidebar-fixed 
    page-header-fixed show" [ngClass]="{
    'has-scroll' : pageHasScroll,
    'page-sidebar-minified': pageSettings.pageSidebarMinified,
    'page-with-footer': pageSettings.pageWithFooter,
    'page-content-full-height': pageSettings.pageContentFullHeight,
    'page-without-sidebar': pageSettings.pageWithoutSidebar,
    'page-with-right-sidebar': pageSettings.pageSidebarRight,
    'page-with-two-sidebar': pageSettings.pageSidebarTwo,
    'page-with-wide-sidebar': pageSettings.pageSidebarWide,
    'page-right-sidebar-collapsed': pageSettings.pageSidebarRightCollapsed,
    'page-with-light-sidebar': pageSettings.pageSidebarLight,
    'page-with-top-menu': pageSettings.pageTopMenu,
    'page-sidebar-toggled': pageSettings.pageMobileSidebarToggled,
    'page-right-sidebar-toggled': pageSettings.pageMobileRightSidebarToggled,
    'p-0': pageSettings.pageEmpty
  }">

    <!--<top-menu *ngIf="pageSettings.pageTopMenu && !pageSettings.pageEmpty"></top-menu>-->
    <header (mostrar)="onMostrar($event)" (toggleSidebarRightCollapsed)="onToggleSidebarRight($event)"
      (toggleMobileSidebar)="onToggleMobileSidebar($event)"
      (toggleMobileRightSidebar)="onToggleMobileRightSidebar($event)" [pageSidebarTwo]="pageSettings.pageSidebarTwo"
      *ngIf="!pageSettings.pageEmpty
		    " [ngClass]="{'p-0': pageSettings.pageEmpty}"></header>

    <sidebar (toggleSidebarMinified)="onToggleSidebarMinified($event)" (hideMobileSidebar)="onHideMobileSidebar($event)"
      *ngIf="(!pageSettings.pageWithoutSidebar && 
				 !pageSettings.pageEmpty) && mostrar == true || 
				 mostrar == null" [pageSidebarTransparent]="pageSettings.pageSidebarTransparent"
      [pageSidebarMinified]="pageSettings.pageSidebarMinified"></sidebar>

    <!--<sidebar-right (hideMobileRightSidebar)="onHideMobileRightSidebar($event)" *ngIf="pageSettings.pageSidebarTwo && !pageSettings.pageEmpty"></sidebar-right>
  -->
    <div *ngIf="mostrar == true || mostrar == null" id="content" class="content" [ngClass]="{
    'content-full-width': pageSettings.pageContentFullWidth,
    'content-inverse-mode': pageSettings.pageContentInverseMode,
    'p-0 m-0': pageSettings.pageEmpty
  }">
      <router-outlet></router-outlet>
    </div>

    <footer *ngIf="pageSettings.pageWithFooter && (mostrar == true || mostrar == 
		 null)"></footer>
  </div>
  <!-- end #page-container -->

</div>