import { Component, OnInit } from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {UserService} from '../../../services/user.service'
import {MovilizadosService} from '../../../services/movilizados.service'
import { Ministerio } from '../../../models/movilizados/ministerio'
import { Periodo } from '../../../models/movilizados/periodo'
import { Resultado } from '../../../models/movilizados/resultado'
import { ListaPeriodo } from '../../../models/movilizados/lista_periodo'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {argv0, report} from 'process';
@Component({
  selector: 'app-informe-integrado-movilizados',
  templateUrl: './informe-integrado-movilizados.component.html',
  styleUrls: ['./informe-integrado-movilizados.component.css'],
  providers:[MovilizadosService, UserService]
})
export class InformeIntegradoMovilizadosComponent implements OnInit {
  pageSettings = pageSettings;

  public titulo = "Informe Integrado de Movilización";
  public descripcion ="Movilizados";
  public icono = "fas fa-child fa-fw";
  public img = document.location.origin + "/assets/movilizados/"
  public ministerio: Ministerio
  //  public lista_ministerios: Array<Ministerio>
  public alert = ""
  public mensaje = ""
  public icon = ""
  public estado = false
  public periodo: Periodo
  public periodos: Array<ListaPeriodo>
  public resultado: Resultado
  public enviar = false
  public validacion = true
  public documentacion: Array<Ministerio>
  public distrito: JSON
  public pastor: JSON
  public cerrar = true
  public guardar = true
  public mostrar_img = false
  public fecha_actual = new Date()
  public vencimiento = true
  public MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  public closeResult: string
  constructor(
    private _movilizadosService: MovilizadosService,                     
    private _userService: UserService,
    private modalService: NgbModal) {
      //console.log(screen.width)
      if(screen.width <= 448){
	this.mostrar_img = true


      }
    }

    ngOnInit(): void {
      this.traePeriodo(null)

      this.traeMinisterios()

      console.log(this.vencimiento)

    }

    // FUNCIONES DE MODAL

    open(content ) {
      let size = "lg"
      let modal_class = {
	windowClass : "modal-holder",   size: size
      }
    this.modalService.open(content, modal_class).result.then((result) => {
	this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
	return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	return 'by clicking on a backdrop';
      } else {
	return  `with: ${reason}`;
      }
    }
    //=====================================================================
    
    traeMinisterios(){
      //console.log(this.lista_ministerios)
      this._movilizadosService.traeMinisterios().subscribe(
	response => {
	  //response.Ministerios.forEach(l => l.registros.forEach(r => r.mensaje = "null"))

	  //	this.lista_ministerios = response.Ministerios
	  this.documentacion = response.Ministerios


	  //console.log(this.documentacion)

	},
	error => {
	  console.log(error)
	}


      )


    }
    traePeriodo(periodo){
      //   console.log("traePeriodo")
      this._movilizadosService.traePeriodo(periodo).subscribe(
	response => {
	  this.alert = response.alert
	  this.icon = response.icon
	  this.mensaje = response.mensaje
	  this.estado = response.estado
	  this.periodo = response.Periodo
	  this.resultado = response
	  this.periodos = response.periodos
	  this.enviar = false
	  //console.log(this.resultado)
	  if(this.periodo){

	    if(this.resultado.Periodo.documentacion.length == 0){
	      this.traeMinisterios()

	    }else{
	      this.documentacion = this.resultado.Periodo.documentacion
	    }
	    this.distrito = this.periodo.distrito

	    this.pastor = this.periodo.responsable

	    this.periodo.fecha_cierre_informe = new 
	    Date(this.periodo.fecha_cierre_informe)

	    this.vencimiento = this.fecha_actual >= this.periodo.fecha_cierre_informe ?  
	      false : true
	  }

	  if(this.vencimiento == false){
	    this.enviar = true
	    this.guardar = false
	    this.cerrar = false
	  }else{

	    this.guardar = response.guardar
	    this.cerrar = response.cerrar

	  }
	  // console.log(this.vencimiento)

	  //	console.log(this.resultado)
	  //	console.log(this.periodo)
	},
	error => {
	  console.log(error)
	}
      )
    }
    validaInputs(){
      this.validacion = true
      this.enviar = true
      for(var i = 0;i < this.documentacion.length; i++){
	let min = this.documentacion[i]
	for(var p=0; p < min.preguntas.length; p++){
	  let pregunta = min.preguntas[p]
	  //console.log(reg.cantidad)
	  pregunta.mensaje = null
	  //console.log(pregunta)
	  if(pregunta.registros == 4){
	    //Validacion nuevos
	    let resultado = this.validaInput(pregunta.nuevos)
	    pregunta.validacion_nuevos = resultado.estado
	    pregunta.mensaje = resultado.mensaje
	    this.validacion = pregunta.validacion_nuevos

	    //validacion finalizados
	    resultado = this.validaInput(pregunta.finalizados)
	    pregunta.validacion_finalizados= resultado.estado
	    pregunta.mensaje = pregunta.mensaje?pregunta.mensaje:  
	      resultado.mensaje
	    this.validacion = (this.validacion == 
			       false)?this.validacion:pregunta.validacion_finalizados
	  }else if(pregunta.registros == 1){
	    //validacion cantidad

	    let resultado = this.validaInput(pregunta.cantidad)
	    pregunta.validacion_cantidad = resultado.estado
	    pregunta.mensaje = pregunta.mensaje?pregunta.mensaje:  
	      resultado.mensaje
	    this.validacion = (this.validacion == 
			       false)?this.validacion:pregunta.validacion_cantidad
	  }
	  /*for(var r=0; r < pregunta.registros.length; r++){
	    let registro = pregunta.registros[r]
	    registro.validacion = null
	    if((registro.totalizador == false && (!registro.valor || 
	    registro.valor == null)) || (registro.totalizador == true && 
	    this.periodo.inicial == true && (!registro.valor || 
	    registro.valor == null))){
	  //console.log(registro)
	  let mensaje = "Campo Requerido!"
	  registro.validacion = false
	  pregunta.mensaje = pregunta.mensaje?pregunta.mensaje:mensaje
	  this.validacion = false

	  }else if(Number.isInteger(Number(registro.valor)) == false || 
	  !/^\s/.test(String(registro.valor)) == false || 
	  Number(registro.valor) < 0){
	  registro.validacion = false
	  this.validacion = false
	  pregunta.mensaje = pregunta.mensaje ? pregunta.mensaje :
	  "Formato Incorrecto!"
	  this.validacion = false

	  //console.log("Formato Incorrecto " + reg.nombre + " en "+ min.nombre)

	  }

	  }*/



	  /*reg.validacion_nuevos = null
	    reg.validacion_total = null
	  //	console.log(reg)
	  //console.log(!/^\s/.test(String(reg.cantidad)))

	  if(!reg.nuevos || reg.nuevos == null ){
	  reg.validacion_nuevos = false
	  reg.mensaje = reg.mensaje ? reg.mensaje : "Campo Requerido!"
	  this.validacion = false
	//console.log("Falta "+ reg.nombre + " en "+ min.nombre)
	}else if(Number.isInteger(Number(reg.nuevos)) == false || 
	!/^\s/.test(String(reg.nuevos)) == false ){
	reg.validacion_nuevos = false
	this.validacion = false
	reg.mensaje = reg.mensaje ? reg.mensaje :"Formato Incorrecto!"

	//console.log("Formato Incorrecto " + reg.nombre + " en "+ min.nombre)

	}
	if(!reg.total || reg.total == null){
	reg.validacion_total = false
	reg.mensaje = reg.mensaje ? reg.mensaje :"Campo Requerido!"
	this.validacion = false
	  //console.log("Falta "+ reg.nombre + " en "+ min.nombre)
	  }else if(Number.isInteger(Number(reg.total)) == false || 
	  !/^\s/.test(String(reg.total)) == false){
	  reg.validacion_total = false
	  this.validacion = false
	  reg.mensaje = reg.mensaje ? reg.mensaje :"Formato Incorrecto!"
	  }*/
	}

      }

    }

    validaInput(registro){
      let resultado = { estado:false, mensaje: null }

      if((!registro || registro == null) && registro != 0){
	console.log(registro)
	resultado.mensaje = "El Campo es Requerido!"
	resultado.estado = false 						
      }else if(Number.isInteger(Number(registro)) == false || 
	       !/^\s/.test(String(registro)) == false || Number(registro) < 0){
	resultado.mensaje = "Formato no Permitido"
      resultado.estado = false 

      }else{
	resultado.estado = true
      }

      return resultado
    }



    enviarDocumentacion(tipo){

      let mensaje = null
      this.enviar = false
      this.validaInputs()
      let params = {
	periodo: this.periodo._id,
	documentacion: this.documentacion,
	accion: tipo
      }
      if(this.validacion == true){
	if(tipo == "cerrar"){
	  //console.log(this.periodo._id)
	  //console.log(this.lista_ministerios)
	  //console.log(this.documentacion)
	  mensaje = "Al ejecutar esta acción no podrá volver a modificar el" 
	  +"informe ¿Esta Seguro de Cerrar el el Informe?"

	  Swal.fire({
	    title: 'Advertencia!',
	    text: mensaje,
	    icon: 'warning',
	    showCancelButton: true,
	    buttonsStyling: false,
	    customClass: {
	      confirmButton: 'btn btn-lg btn-success mr-3',
	      cancelButton: 'btn btn-lg btn-danger',

	    },
	    confirmButtonText: 'Sí, Cerrar',
	    cancelButtonText: 'Cancelar'
	  }).then((result) => {
	    /* Read more about isConfirmed, isDenied below */
	    if (result.isConfirmed) {
	      console.log(params)
	      this._movilizadosService.enviarDocumentacionPeriodo(params).subscribe(
		response =>{
		  mensaje = response.mensaje
		  Swal.fire({
		    title: "Éxito!",
		    text: mensaje,
		    icon: 'success'
		  });
		  this.traePeriodo(params.periodo)
		  console.log(response)
		},
		error =>{
		  console.log(error)
		}
	      )
	      //Swal.fire('Saved!', '', 'success')
	    } 	})
	    //return	/**/
	}else if(tipo == "guardar"){
	  this._movilizadosService.enviarDocumentacionPeriodo(params).subscribe(
	    response =>{
	      mensaje = response.mensaje
	      Swal.fire({
		title: "Éxito!",
		text: mensaje,
		icon: 'success'
	      });
	      this.traePeriodo(params.periodo)
	      console.log(response)
	    },
	    error =>{
	      console.log(error)
	    }
	  )
	}

      }else{

	Swal.fire({
	  title: "Error!",
	  text: "Tiene Valores con Formatos Incorrectos!",
	  icon: 'warning'
	});

      }

    }
    totalizador( pregunta, documentacion){
      let registro =  this.documentacion[documentacion].preguntas[pregunta]
      if(registro.registros == 4){
	//Nuevos
	registro.nuevos = Number(registro.nuevos?registro.nuevos:0)
	registro.nuevos = (Number(registro.nuevos) >= 
			   0)?Number(registro.nuevos):0
	//Finalizados
	registro.finalizados = 
	  Number(registro.finalizados?registro.finalizados:0)
	registro.finalizados =
	  (Number(registro.finalizados) >= 0)?Number(registro.finalizados):0
	//Nuevos Activo
	registro.nuevo_activos = (Number(registro.activos) + 
				  Number(registro.nuevos)) - 
				  Number(registro.finalizados)
	if(registro.nuevo_activos < 0){

	  registro.finalizados =Number(registro.finalizados) + 
	    Number(registro.nuevo_activos)

	  registro.nuevo_activos = 0
	}


	//Nuevos Acumulado
	registro.nuevo_acumulado = Number(registro.acumulado) + 
	  Number(registro.nuevos)


      }else if(registro.registros == 1){
	registro.cantidad = Number(registro.cantidad?registro.cantidad:0)
	registro.cantidad = (Number(registro.cantidad) >= 
			     0)?Number(registro.cantidad):0

      }

      //console.log(this.documentacion)
      /*
      //if(this.periodo.inicial == false){

      let reg =  
      this.documentacion[documentacion].preguntas[pregunta].registros[registro]

      let registros = this.documentacion[documentacion].preguntas[pregunta].registros
      //console.log(registros)
      //Activos
      let total = registros.find(t => t._id == "606e3d9fabf64fc69c7a16d1")
      //	let total = registros.find(t => t.totalizador == true)
      let valor_nuevo = 0
      //Nuevos
      let nuevos = registros.find(n => n._id == "606e3d9fabf64fc69c7a16cf")

      //Finalizados
      let finalizados = registros.find(f => f._id == 
      "606e3d9fabf64fc69c7a16d0")
      //Acumulado
      let acumulado = registros.find(a=> a._id == "609313d6e5d21606dff2fab3")
      let valor_nuevo_acumulado = 0
      if(nuevos){

      nuevos.valor = String((Number(nuevos.valor) < 0 || !nuevos.valor) ? 0 : 
      nuevos.valor)
      valor_nuevo_acumulado = Number(acumulado.valor) + 
      Number(nuevos.valor)
      }
      //let valor_nuevo_acumulado = 0
      //console.log(finalizados)
      if(finalizados){

      finalizados.valor = String((Number(finalizados.valor) < 0 || 
      !finalizados.valor) ? 0 : 
      finalizados.valor)
      valor_nuevo = (Number(total.valor) + Number(nuevos.valor)) - 
      Number(finalizados.valor)
      if(valor_nuevo < 0){
      let valor_finalizado = Number(finalizados.valor) + 
      Number(valor_nuevo)
      finalizados.valor = String(valor_finalizado)
      valor_nuevo = 0

      }
      //console.log(valor_nuevo)
      }else if(nuevos){
      valor_nuevo = Number(total.valor) + Number(nuevos.valor)

      }

      if(nuevos && finalizados){

      total.valor_nuevo = String(valor_nuevo?valor_nuevo:0  )

      acumulado.valor_nuevo = 
      String(valor_nuevo_acumulado?valor_nuevo_acumulado:0)
      }

      console.log(registros)
      //}

       */
    }

    seleccionarPeriodo(id){
      this.traePeriodo(id)
      //console.log(id)
    }
    /*cantidad_registros(registros){
      let cantidad = 0
      registros.map(function(r){
      if(r.hidde == false){ cantidad++ }
      })
    //console.log(cantidad)
    return cantidad

    }*/
}
