import { UserService } from "./user.service";
import { Injectable } from '@angular/core';

@Injectable()
export class UploadService {
  public identity;
  private token;

  constructor(private _userService: UserService) {
    this.token = this._userService.getToken();
  }

  makeFileRequest(url: string, params, files: File[], name: string, token?) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();

      for (let i = 0; i < files.length; i++) {
        formData.append(name, files[i], files[i].name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', this.token);

      xhr.send(formData);
    }).catch(error => {
      console.error('Error uploading file:', error);
      throw error;
    });
  }
}