import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalService } from '../services/localService'

@Injectable({
  providedIn: 'root'
})

export class RutaGuard implements CanActivate {
  constructor(private _router: Router, private _localService: LocalService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var rutas
    let permiso = false
    rutas = this._localService.getJsonValue("rutas")
    if (rutas == null) {
      rutas = []
      permiso = true
    }else{
      rutas = (JSON.parse(rutas))
      for (let r = 0; r < rutas.length; r++) {
        const acceso = rutas[r];
        if (acceso == state.url) {
          permiso = true
        }
      }
    }
    //console.log(rutas)







    if (permiso == false) {
      this._router.navigate(["/inicio"])
    }

    return permiso
  }
}

