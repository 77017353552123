//Importar modulos del router de Angular
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



//Componentes IATUCh
import { InicioComponent } from './modulos/inicio/inicio.component';
import { ModificarinformacionComponent } from './modulos/administrar/modificarinformacion/modificarinformacion.component';
import { AsistenciaComponent } from './modulos/cgas/asistencia/asistencia.component'
import { ReporteAsistenciaComponent } from './modulos/cgas/reporte-asistencia/reporte-asistencia.component'
//Ejemplos Sharepoint

//Componentes Movilizados
import { InformeIntegradoMovilizadosComponent } from
  './modulos/movilizados/informe-integrado-movilizados/informe-integrado-movilizados.component'
import { EstadisticasMovilizadosComponent } from
  './modulos/movilizados/estadisticas-movilizados/estadisticas-movilizados.component'
import { ReporteMovilizadosComponent } from
  './modulos/movilizados/reporte-movilizados/reporte-movilizados.component'

//Componentes APS
import { GenerarPagoComponent } from
  './modulos/aps/generar-pago/generar-pago.component'


// Guard para validar privilegio al ingresar url
import { RutaGuard } from './guards/ruta.guard'
import { GestionUsuariosComponent } from './modulos/administrar/gestion-usuarios/gestion-usuarios.component';
import { NotificacionesComponent } from './modulos/administrar/notificaciones/notificaciones.component';

// Array de Configuracion de Rutas
const appRoutes: Routes = [
  //Rutas IATUCh
  //{path: '', component: InicioComponent, data: { title: 'Inicio'}},
  { path: '', component: InicioComponent, data: { title: 'Inicio' }, pathMatch: "full" },
  { path: 'modificarinformacion', component: ModificarinformacionComponent, data: { title: 'Modificar Cuenta' } },
  { path: 'modificarinformacion/:generica', component: ModificarinformacionComponent, data: { title: 'Modificar Cuenta' } },
  { path: 'gestionusuarios', component: GestionUsuariosComponent, data: { title: 'Gestión de Usuarios' }, canActivate: [RutaGuard] },
  { path: 'notificaciones', component: NotificacionesComponent, data: { title: 'Notificaciones' }, canActivate: [RutaGuard] },
  //CGAS
  { path: 'asistencia-evento', component: AsistenciaComponent, data: { title: 'Asistencia Evento' }, canActivate: [RutaGuard] },
  { path: 'reporte-asistencia-evento', component: ReporteAsistenciaComponent, data: { title: 'Reporte Asistencia Evento' }, canActivate: [RutaGuard] },
  //Movilizados
  {
    path: 'informe-integrado-movilizados', component:
      InformeIntegradoMovilizadosComponent, data:
      { title: 'Informe Integrado de Movilización' },
    canActivate: [RutaGuard]
  },
  {
    path: 'estadisticas-movilizados', component:
      EstadisticasMovilizadosComponent, data: {
        title:
          'Estadisticas'
      }, canActivate: [RutaGuard]
  },

  {
    path: 'reporte-movilizados', component:
      ReporteMovilizadosComponent, data: { title: 'Reporte' }, canActivate:
      [RutaGuard]
  },


  //Rutas APS

  {
    path: 'aps-generar-pago', component: GenerarPagoComponent, data: {
      title:
        'Generar Pago'
    }, canActivate: [RutaGuard]
  },
  {
    path: 'seguros',
    loadChildren: () => import('./modulos/seguros/seguros.module').then(m => m.SegurosModule)
  },
  {
    path: 'fpv',
    loadChildren: () => import('./modulos/fpv/fpv.module').then(m => m.FPVModule)
  },

  {
    path: 'users',
    loadChildren: () => import('./modulos/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },

  //Ruta por Defecto
  { path: '**', component: InicioComponent, data: { title: 'Inicio' } },





];

//Exportar el Modulo del Router
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> =
  RouterModule.forRoot(appRoutes);
