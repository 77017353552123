<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
  <li class="breadcrumb-item">{{descripcion}}</li>
  <li class="breadcrumb-item active">{{ titulo }}</li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="fa fa-edit"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<div class="row">
  <div class="col-md-12">
    <div class="panel panel-iatuch">
      <!-- begin panel-heading -->
      <div class="panel-heading ui-sortable-handle">
        <div class="panel-heading-btn">

        </div>
        <h4 class="panel-title"><i class="fas fa-info-circle fa-fw"></i> Notificaciones</h4>
      </div>
      <!-- end panel-heading -->
      <!-- begin panel-body -->
      <div class="panel-body ">
        <div class="row">
          <div class="col-lg-6">
            <form #notificaciones="ngForm">
              <div class="form-group">
                <label>Asunto: </label>
                <input type="text" class="form-control" placeholder="Ingrese asunto" name="asunto" #asunto="ngModel" [(ngModel)]="notificacion.asunto" required>
                <div class="invalid-feedback d-block" *ngIf="!asunto.valid && asunto.touched">Campo
                  Requerido</div>
              </div>
              <div class="form-group">
                <label>Mensaje: </label>
                <textarea class="form-control" placeholder="Ingrese mensaje aqui" rows="7" name="mensaje" #mensaje="ngModel" [(ngModel)]="notificacion.mensaje" required></textarea>
                <div class="invalid-feedback d-block" *ngIf="!mensaje.valid && mensaje.touched">Campo
                  Requerido</div>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <h6>Selecionar destinatarios</h6>
            <div class="col-lg-10">
              <label class="checkbox-label">
                <input type="checkbox" (click)="agregarPrivilegio('todos')" [(ngModel)]="todos"> <!-- [checked]="notificacion.privilegios.tes_asist && notificacion.privilegios.jefe_cont && notificacion.privilegios.cont_campo && notificacion.privilegios.cont_dj"-->
                <span class="checkbox-custom"></span>
                Todos
              </label>
            </div>
            <div *ngFor="let p of privilegios;let indice=index" class="col-lg-10">
              <label class="checkbox-label">
                <input type="checkbox" (click)="agregarPrivilegio(p.nombre)" [checked]="privilegios[indice].seleccionado">
                <span class="checkbox-custom"></span>
                {{p.descripcion}}
              </label>
            </div>
            <hr>
            <div class="col-lg-12">
              <label class="checkbox-label">
                <input type="checkbox" [(ngModel)]="filtros">
                <span class="checkbox-custom"></span>
                Filtrar por entidad
              </label>
              <div *ngIf="filtros" class="form-group">
                <div class="email-to">
                  <label class="control-label">Codigo Entidad: </label>
                  <tag-input class="form-control form-control-lg" [(ngModel)]="tags" name="tags" [placeholder]="'Añadir Codigo'" (keyup.enter) ="agrega()" [secondaryPlaceholder]="'Codigo Entidad'"></tag-input>
                </div>
              </div>
              <button [disabled]="notificaciones.invalid" class="pull-right btn btn-info" (click)="enviar(notificaciones)" type="button">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
