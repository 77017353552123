<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<div class="row">
  <div class="col-md-12">
    <panel variant="iatuch" title="{{ titulo_panel }}">
    <div class="form-inline col-md-12 mb-3">

      <label>Ministerio de la Mujer:&nbsp;&nbsp;</label>
      
      
    </div>

    <div class="row">
      <div class="col-md-8 border ml-3">

	<div class="panel-body ng-star-inserted">
	  <div class="height-sm">
	    <ngx-charts-line-chart
	      [animations]="false"
	      [scheme]="chartColor"
	      [results]="chartData"
	      [xAxis]="true"
	      [yAxis]="true"
	      [legend]="true"
	      [legendTitle]="legend"
	      [showXAxisLabel]="true"
	      [xAxisLabel]="'Meses'"
	      [showYAxisLabel]="true"
	      [yAxisLabel]="'Estudios Bíblicos'"
	      [roundDomains]="true"
	      [autoScale]="true">
	    </ngx-charts-line-chart>
	  </div>
	</div>
      </div>
      <div class="col-md-3 ml-3">

	<table class="table">
	  <thead >
	    <tr>
	      <th scope="col">Distrito</th>

	      <th scope="col">Proyectos Vive</th>

	      <th scope="col">Estudios Bíblicos</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr>
	      <td>El Bosque</td>
	      <td>0</td>
	      <td>0</td>
	    </tr>
	    <tr>
	      <td>Puente Alto</td>
	      <td>3</td>
	      <td>1</td>
	    </tr>
	  </tbody>
	  <tfoot>
	    <tr>
	      <th scope="row">Total: </th>
	      <td>3</td>
	      <td>1</td>
	    </tr>
	  </tfoot>
	</table>

	

      </div>

    </div>
    </panel>
  </div>

</div>

