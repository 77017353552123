import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service'
import {CGASService} from '../../../services/cgas.service'
import {Evento} from '../../../models/cgas/evento'
import {Delegado} from '../../../models/cgas/delegado'
import pageSettings from '../../../config/page-settings';
import {from} from 'rxjs';

@Component({

	selector: 'app-asistencia',
	templateUrl: './asistencia.component.html',
	styleUrls: ['./asistencia.component.css'],
	providers: [CGASService, UserService]
})
export class AsistenciaComponent implements OnInit {

	pageSettings = pageSettings;

	public titulo = "Asistencia";
	public descripcion = "Eventos";
	public icono = "fas fa-calendar fa-fw";
	public evento: Evento//new Evento("", JSON, "", new Date(), new Date(), 
	//	    "", 0)
	//public lista_eventos = []
	public lista_eventos: Array<Evento>
	public titulo_panel = this.titulo
	public rut: String
	public delegado: Delegado
	public seleccionado = ""
	public mensaje_consulta_delegado: String
	public estado_consulta_delegado: Boolean

	public user_img = document.location.origin +
		"/assets/img/user/logoiasd.png";
	public delegado_img: String
	public ruta_img: String = document.location.origin +
		'/assets/delegado/'

	constructor(private _cgasService: CGASService, private _userService:
		UserService
	) {

	}

	ngOnInit() {
		//console.log(this.evento)
		this.traeEventos()
	}
	traeEventos() {
		//console.log("trae Eventos")
		//returnlet campo = "MCCh"
		let campo = "MCCh"
		this._cgasService.traeEventos().subscribe(
			response => {
				this.lista_eventos = response.eventos
			}
		)
	}
	seleccionarEvento(evento) {
		//console.log(evento)
		this.evento = new Evento(evento._id,
			evento.campo, evento.nombre,
			evento.fecha_inicio,
			evento.fecha_termino,
			evento.comentario, evento.activo)
		this.titulo_panel = this.titulo + " - " +
			this.evento.nombre + " - " +
			this.evento.campo['nombre']
		this.rut = ''
		this.estado_consulta_delegado = undefined
	}
	presenteDelegado() {
		this.estado_consulta_delegado = undefined
		this._cgasService.asisteDelegado(this.rut, this.evento._id).subscribe(
			response => {

				this.estado_consulta_delegado = response.estado
				this.mensaje_consulta_delegado = response.mensaje
				this.delegado = response.bitacora.delegado
				if (this.delegado != undefined) {
					this.delegado_img = this.ruta_img +
						this.delegado['rut'] + '.jpg'
				}

			}
		)
		//console.log(this.rut)
		//console.log(this.evento._id)
	}

}
