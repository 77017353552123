
<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<div class="row">
  <div class="col-md-12">
    <panel variant="iatuch" title="{{ titulo_panel }}">
    <div class="form-inline col-md-12 mb-3">

      <label>Eventoo:&nbsp;&nbsp;</label>
      <select *ngIf="eventos" name="eventos" [(ngModel)] = "seleccionado" 
			      class="form-control" placeholder ="Seleccione..." (change) = 
				     "seleccionarEvento(seleccionado)">
	<option value="" disabled selected >Seleccione...</option>
	<option [ngValue]="ev" *ngFor="let ev of eventos">{{ev.nombre}} </option>
      </select>
      &nbsp;&nbsp;&nbsp;&nbsp;
      
    </div>

    <div class="row">
      <div *ngIf="reporte_evento" class="col-md-8 border ml-3">

	<div class="panel-body ng-star-inserted">
	  <div class="height-lg">
	    <ngx-charts-pie-chart
	      [scheme]="chartColor"
	      [results]="pieChartData"
	      [animations]="true"
	      [legend]="false"
	      [labels]="true"
	      [arcWidth]="0.5"
	      [doughnut]="true">
	    </ngx-charts-pie-chart>
	  </div>
	</div>
      </div>
      <div *ngIf="reporte_evento" class="col-md-3 ml-3">

	<table class="table">
	  <thead >
	    <tr>
	      <th scope="col">Datos</th>
	      <th scope="col">Cantidad</th>
	      <th scope="col">%</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr>
	      <th scope="row">Delegados Presentes: </th>
	      <td>{{reporte_evento.presentes}}</td>
	      <td>{{reporte_evento.porc_presentes | number:0}}%</td>
	    </tr>
	    <tr>
	      <th scope="row">Delegados Ausentes: </th>
	      <td>{{reporte_evento.ausentes}}</td>
	      <td>{{reporte_evento.porc_ausentes | number:0}}%</td>
	    </tr>
	  </tbody>
	  <tfoot>
	    <tr>
	      <th scope="row">Total: </th>
	      <td>{{reporte_evento.total}}</td>
	      <td>100%</td>
	    </tr>
	  </tfoot>
	</table>

	<table class="table">
	  <thead>
	    <tr>
	      <th colspan="3" scope="col">Ultimo Delegado</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr>
	      <td rowspan="2"> <img width="60px" height="60px" 
			   *ngIf="delegado.foto == 'NULL'" [src]="user_img" > 
			   <img *ngIf="delegado.foto != 'NULL'" 
						 [src]="delegado_img" >
	      </td>
			   <th>Nombre: </th>
			   <td>{{delegado.nombre}}</td>
	    </tr>
	    <tr>
			   <th>Rut:</th>
			   <td>{{delegado.rut}}</td>
	    </tr>
	    <tr>
	      <th>Iglesia: </th>
	      <td colspan="2">{{delegado.iglesia}}</td>
	    </tr>
	    <tr>
	      <th>Distrito: </th>
	      <td colspan="2">{{delegado.distrito}}</td>
	    </tr>
	  </tbody>

	</table>

      </div>

    </div>
    </panel>
  </div>

</div>

