import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Global} from './global';
import {UserService} from './user.service';
import {LocalService} from './localService';


@Injectable()

export class CGASService {
	public url: string;
	public identity;
	public token;
	public entidad;

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _localService: LocalService
	) {
		this.url = Global.url;
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();

	}
	traeEventos(): Observable<any> {

		//entidad.campo = this.identity.campo;
		let params = {};
		//console.log(params); return false;
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url + 'trae-eventos',
			params, {headers: headers})
		return resultado;

	}
	asisteDelegado(rut, evento): Observable<any> {
		let params = {rut: rut, evento: evento};
		//definir las cabeceras
		let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token);
		//Hacer peticion ajax
		var resultado = this._http.post(this.url + 'asiste-delegado',
			params, {headers: headers})
		return resultado;
	}
	reporteEvento(campo, evento): Observable<any> {
		let params = {campo: campo, evento: evento}

		let headers = new HttpHeaders().set('Content-Type',
			'application/json').set('Authorization', this.token)

		var resultado = this._http.post(this.url + 'reporte-evento',
			params, {headers: headers})
		return resultado;
	}





}
