<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a> / Movilizados / 
    Reporte</li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header"> <i class="{{icono}}"></i> {{ titulo }} <small> {{ descripcion }}</small></h1>
<!-- end page-header -->
<div class="row ">
  <div class="col-md-12">
    <div class="panel panel-iatuch">
      <div class="panel-heading">
	<h4 class="panel-title">
	  {{titulo}} </h4>
      </div>

      <div class="panel-body">
	<div class="col-md-12">

	  <h3 *ngIf="ministerio">{{ ministerio.nombre }} <button class="btn 
						    btn-xs btn-primary" 
								 (click)="open(modal_ministerio)"><i 
								 class="fa 
									fa-edit"></i></button></h3>

	  <div class="col-md-12 form-inline border-bottom border-top pb-2 
	    pt-2">
	    <div class="col-md-2 p-3 form-inline">
		<b>Campo: &nbsp; </b>
		<select class="form-control form-control-sm " name="campos" 
							     id="campos">
		  <option value="">Todos</option>
		</select>
	    </div>

	    <div class="col-md-2">
	      <b>Distrito: &nbsp;</b>
	      <select class="form-control form-control-sm" name="campos" id="campos">
		<option value="">Todos</option>
	      </select>
	    </div>
	    <div class="col-md-2">
	      <b>Periodo: &nbsp;</b>
	      <select class="form-control form-control-sm" name="campos" 
							   id="campos">
		<option value="">Todos</option>
	      </select>
	    </div>
	    <div class="col-md-2">
	      <b>Estado: &nbsp;</b>
	      <select class="form-control form-control-sm" length="10" 
							   name="campos" 
							   id="campos">
		<option value="">Todos</option>
	      </select>
	    </div>
	    <div class="col-md-2">
	      <button class="btn btn-primary btn-sm"><i class="fa 
			     fa-search"></i> Buscar</button>
	    </div>
	  </div>

	</div>
	<br>

	<div *ngIf="ministerio" class="col-md-12 ">
	  <div class="table-responsive">
	    <table class="table table-striped table-sm table-bordered">
	      <thead>
		<tr>
		  <th rowspan="2">Distrito</th>
		  <th rowspan="2">Periodo</th>
		  <th rowspan="2">Estado</th>
		  <th *ngFor="let pr of ministerio.preguntas;let ipr=index" 
		      [attr.colspan]="pr.registros">{{ pr.nombre }}</th>
		  <th rowspan="2">Responsable</th>
		  <th rowspan="2">Campo</th>
		  <th rowspan="2">Cierre</th>
		</tr>
		<tr >
		  <ng-template ngFor let-pregunta [ngForOf] = " 
							       ministerio.preguntas" 
			       [ngIf] 
			       = 
			       "
				ministerio.preguntas">

		    <th *ngIf = "pregunta.registros == 4" >Nuevos</th>
		    <th *ngIf = "pregunta.registros == 4" >Finalizados</th>
		    <th *ngIf = "pregunta.registros == 4" >Activos</th>
		    <th *ngIf = "pregunta.registros == 4" >Acumulado</th>

		    <th *ngIf = "pregunta.registros == 1" >Cantidad</th>
		  </ng-template>
		</tr>
	      </thead>
	      <tbody>
		<tr *ngFor="let p of periodos;let ip=index">
		  <td>{{ p.distrito.nombre }}</td>
		  <td>{{ traeMes(p.periodo) }}</td>
		  <td>{{ p.estado.nombre }}</td>

		  <ng-template [ngIf] = "p.documentacion.length > 0">
		    <ng-template ngFor let-doc [ngForOf] = "p.documentacion">
		      <ng-template ngFor let-pregunta                        
				   [ngForOf] = "doc.preguntas">

			<td *ngIf="pregunta.registros == 4 && doc._id == 
			  ministerio._id">
			  {{ pregunta.nuevos }}
			</td>

			<td *ngIf="pregunta.registros == 4 && doc._id == 
			  ministerio._id">
			  {{ pregunta.finalizados}}
			</td>
			<td *ngIf="pregunta.registros == 4 && doc._id == 
			  ministerio._id">
			  {{ 
			  pregunta.nuevo_activos?pregunta.nuevo_activos:pregunta.activos 
			  }}
			</td>
			<td *ngIf="pregunta.registros == 4 && doc._id == 
			  ministerio._id">
			  {{ 
			  pregunta.nuevo_acumulado?pregunta.nuevo_acumulado:pregunta.acumulado}}
			</td>
			<td *ngIf="pregunta.registros == 1 && doc._id == 
			  ministerio._id">
			  {{ pregunta.cantidad }}
			</td>
			<!--<ng-template ngFor let-registro [ngForOf] = 
     "pregunta.registros">
     <td *ngIf="doc._id == ministerio._id">
     {{ 
     registro.valor_nuevo?registro.valor_nuevo:registro.valor  
     }}
     </td>
     </ng-template>-->
		      </ng-template>
		    </ng-template>
		  </ng-template>

		  <ng-template [ngIf] = "p.documentacion.length == 0">
		    <ng-template ngFor let-pregunta                                      
				 [ngForOf] = " ministerio.preguntas">

		      <td *ngIf="pregunta.registros == 4">
			{{ pregunta.nuevos }}
		      </td>

		      <td *ngIf="pregunta.registros == 4">
			{{ pregunta.finalizados}}
		      </td>
		      <td *ngIf="pregunta.registros == 4">
			{{ 
			pregunta.nuevo_activos?pregunta.nuevo_activos:pregunta.activos 
			}}
		      </td>
		      <td *ngIf="pregunta.registros == 4">
			{{ 
			pregunta.nuevo_acumulado?pregunta.nuevo_acumulado:pregunta.acumulado}}
		      </td>
		      <td *ngIf="pregunta.registros == 1">
			{{ pregunta.cantidad }}
		      </td>
		    </ng-template>
		  </ng-template>
		  <td>{{ p.responsable.usuario }}</td>
		  <td>{{ p.campo.sigla }}</td>
		  <td>{{ (p.fecha_cierre_informe | date:'dd/MM/yyyy HH:mm')+" 
		    Hrs"}}</td>
		</tr>
	      </tbody>
	    </table>
	  </div>
	</div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal_ministerio let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info"><i class="fa fa-check-circle"></i> 
      Seleccione Enfasis</h4>
    <button type="button" class="close btn btn-white" (click)="d('Cross 
		  click')">×</button>
  </div>
  <div class="modal-body">
    <div class="card">
      <div *ngIf="ministerios" class="card-body">
	<table class="table table-striped table-sm">
	  <thead>
	    <tr>
	      <th>Enfasis</th>
	      <th>Acción</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr *ngFor="let m of ministerios">
	      <td>{{ m.nombre }}</td>
	      <td> <button class="btn btn-success btn-xs" 
			   (click)="cambiarEnfasis(m._id)"><i class="fa 
				    fa-check-circle"></i></button> </td>
	    </tr>
	  </tbody>

	</table>	

      </div>


    </div>

  </div>
  <div class="modal-footer">
    <button id="cerrar_modal" class="btn btn-danger" (click)="d('Cross 
		click')">Cerrar</button>
  </div>
</ng-template>

