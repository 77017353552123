// Core Module
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {routing, appRoutingProviders} from './app.routing';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule, LOCALE_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
//import { MatSortModule, MatTableModule }         from '@angular/material';
//import * as global                               from './config/globals';

// Main Component
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {SidebarRightComponent} from './components/sidebar-right/sidebar-right.component';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {FooterComponent} from './components/footer/footer.component';
import {PanelComponent} from './components/panel/panel.component';
import {FloatSubMenuComponent} from './components/float-sub-menu/float-sub-menu.component';


// Component Module
//import { AgmCoreModule }                   from '@agm/core';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

//import { FullCalendarModule }              from '@fullcalendar/angular';

import {NgxChartsModule} from '@swimlane/ngx-charts';
import {TrendModule} from 'ngx-trend';
//import { HighlightJsModule }               from 'ngx-highlight-js';
//import { CountdownModule }                 from 'ngx-countdown';
//import { ChartsModule }                    from 'ng4-charts/ng4-charts';
import {TagInputModule} from 'ngx-chips';
//import { SweetAlert2Module }               from '@sweetalert2/ngx-sweetalert2';
//import { Ng2TableModule }                  from 'ngx-datatable/ng2-table';
//import { NvD3Module }                      from 'ng2-nvd3';
//import { NgxDaterangepickerMd }            from 'ngx-daterangepicker-material';
//import 'd3';
//import 'nvd3';
//import { CalendarModule, DateAdapter }     from 'angular-calendar';
//import { adapterFactory }                  from 'angular-calendar/date-adapters/date-fns';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';




import {InicioComponent} from './modulos/inicio/inicio.component';

import {GestionUsuariosComponent} from './modulos/administrar/gestion-usuarios/gestion-usuarios.component';
import {ModificarinformacionComponent} from './modulos/administrar/modificarinformacion/modificarinformacion.component';
//import { RutaGuard } from './guards/ruta.guard';
//import { APP_BASE_HREF } from '@angular/common';


/*********** Pipe *****************/
//importar pipe
import {CalculadoraPipe} from './pipes/calculadora.pipe';
import {ThousandsPipe} from './pipes/thousands.pipe';
//import { LOCALE_ID } from '@angular/core';
import {registerLocaleData, APP_BASE_HREF, CommonModule} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import {RutaGuard} from './guards/ruta.guard';
import {NotificacionesComponent} from './modulos/administrar/notificaciones/notificaciones.component';
import {AsistenciaComponent} from './modulos/cgas/asistencia/asistencia.component';
import {ReporteAsistenciaComponent} from './modulos/cgas/reporte-asistencia/reporte-asistencia.component';
import { InformeIntegradoMovilizadosComponent } from './modulos/movilizados/informe-integrado-movilizados/informe-integrado-movilizados.component';
import { EstadisticasMovilizadosComponent } from './modulos/movilizados/estadisticas-movilizados/estadisticas-movilizados.component';

//Ejemplos Sharepoint
import { ReporteMovilizadosComponent } from './modulos/movilizados/reporte-movilizados/reporte-movilizados.component';
import { GenerarPagoComponent } from 
'./modulos/aps/generar-pago/generar-pago.component';
import { SharedModule } from './modulos/shared-module/shared-module.module';
import { EntidadService } from './services/entidad.service';
import { UserService } from './services/user.service';
import { MicrosoftAuthService } from './services/authentication.service';
import { ErrorInterceptor } from './interceptor/error.interceptor';




registerLocaleData(localeEs, 'es', localeEsExtra);


@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		SidebarComponent,
		SidebarRightComponent,
		TopMenuComponent,
		FooterComponent,
		PanelComponent,
		FloatSubMenuComponent,
		CalculadoraPipe,
		ThousandsPipe,
		InicioComponent,
		GestionUsuariosComponent,
		ModificarinformacionComponent,
		NotificacionesComponent,
		AsistenciaComponent,
		ReporteAsistenciaComponent,
		InformeIntegradoMovilizadosComponent,
		EstadisticasMovilizadosComponent,
		ReporteMovilizadosComponent,
		GenerarPagoComponent,
	],
	imports: [
		NgbModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		LoadingBarRouterModule,
		NgxChartsModule,
		NgSelectModule,
		NgxChartsModule,
		PerfectScrollbarModule,
		TagInputModule,
		TrendModule,
		NgSelectModule,
		routing,
		HttpClientModule
	],
	providers: [
		EntidadService,
		UserService,
		MicrosoftAuthService,
		appRoutingProviders,
		RutaGuard,
		{provide: APP_BASE_HREF, useValue: '/'},
		{provide: LOCALE_ID, useValue: 'es'},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		Title, {

			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}],
	bootstrap: [AppComponent]
})

export class AppModule {
	constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
		router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				var title = 'Personas UCh | ' +
					this.route.snapshot.firstChild.data['title'];
				this.titleService.setTitle(title);
			}
		});
	}
}
